import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const WidthCheck = ({ handleOpenValue,component }) => {
  console.log(component,"component coming")
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPortion, setIsOpenPortion] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (width < 1200) {
      setIsOpen(true);
      handleOpenValue(true, false);
    } else {
      setIsOpen(false);
      setIsOpenPortion(false);
      handleOpenValue(false, false);
    }
  }, [width]);
  const toggleOpenPortion = () => {
    setIsOpenPortion(!isOpenPortion);
    setIsOpen(!isOpen);
    handleOpenValue(!isOpen, !isOpenPortion);
  };
  return (
    <div>
      {width < 1200 && (
        <div className={component==="live"||component==="chat-sec"?"toggles-icon-chat":"toggles-icon"}>
          <i
            className={`fa fa-${isOpenPortion ? "times":"bars" } icon ` }
            onClick={toggleOpenPortion}
          />
        </div>
      )}
    </div>
  );
};

export default WidthCheck;
