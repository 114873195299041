import React from 'react'
import { Button, Col, Row } from "reactstrap";
import { CreatorAnimation, FeeAnimation } from "./LottieAnimation/LottieAnimations";
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import stripePromise from "Api/Stripe";
import EroxrFeeModal from './Modals/EroxrFeeModal';

const UserCheck = ({userData}) => {
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
     const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
    <Elements stripe={stripePromise} className="">
        <EroxrFeeModal isOpen={showModal} toggle={closeModal} />
      </Elements>
      {userData?.eroxrFee === false && userData?.creator === false && (
          <Col xl={10} className="">
            <Row className="justify-content-center mt-5">{FeeAnimation()}</Row>
            <h1 className="text-center">
              <Button
                type="submit"
                onClick={() => setShowModal(true)}
                className="reset-button mr-2"
                style={{ paddingLeft: "200px", paddingRight: "210px" }}
              >
                Buy our MemberShip!
              </Button>
            </h1>
          </Col>
        )}
        {userData?.eroxrFee === false && userData?.creator === true && (
          <Col xl={10} className="">
            <Row className="justify-content-center mt-5">{FeeAnimation()}</Row>
            <h1 className="text-center">
              <Button
                type="submit"
                onClick={() => setShowModal(true)}
                className="reset-button mr-2"
                style={{ paddingLeft: "200px", paddingRight: "210px" }}
              >
                Buy our MemberShip!
              </Button>
            </h1>
          </Col>
        )}
        {userData?.eroxrFee === true && userData?.creator === false && (
          <Col xl={10} className="">
            <Row className="justify-content-center mt-5">
              {CreatorAnimation()}
            </Row>
            <h1 className="text-center">
              <Button
                type="submit"
                onClick={() => history.push("/admin/memberShip")}
                className="reset-button mr-2"
                style={{ paddingLeft: "200px", paddingRight: "210px" }}
              >
                Become a creator!
              </Button>
            </h1>
          </Col>
        )}
        </>
  
  )
}

export default UserCheck