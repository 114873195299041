import Pusher from 'pusher-js';

const pusher = {
  appId: process.env.REACT_APP_PUSHER_APP_ID,
  key: process.env.REACT_APP_PUSHER_APP_KEY,
  secret: process.env.REACT_APP_PUSHER_APP_SECRET,
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  useTLS: true,
};

const PusherConfig = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY,pusher);

export default PusherConfig;