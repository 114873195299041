import * as actionTypes from '../constants';


export const getPostsReducer = (state = { posts: [] }, action) => {
  switch (action.type) {
     case actionTypes.GET_POSTS_SUCCESS:
      // Filter out duplicates and append new posts to existing posts
      const newPosts = action.payload.filter((newPost) => {
        return !state.posts.some((existingPost) => existingPost._id === newPost._id);
      });

      return { posts: [...state.posts, ...newPosts] }; // Append new posts to existing posts
    case actionTypes.GET_POSTS_FAIL:
      return { error: action.payload };
    case actionTypes.Poll_Counter_Increment:
      const { objectId, objectContainingCounterId, counterValue } = action.payload;
      const updatedArray = state.posts.map((object) => {
        if (object._id === objectId) {
          const updatedOptions = object.options.map((option) => {
            if (option._id === objectContainingCounterId && option.counter === counterValue) {
              return { ...option, counter: option.counter + 1 };
            }
            return option;
          });
          return { ...object, options: updatedOptions };
        }
        return object;
      });
      return {
        ...state,
        posts: updatedArray,
      };

    case actionTypes.ADD_USER_TO_POST:
      const { postId, userId } = action.payload;
      const updatedPostArray = state.posts.map((object) => {
        if (object._id === postId) {
          return {
            ...object,
            payerId: [...object.payerId, userId], // Push the userId to the payerId array
          };
        }
        return object;
      });
      return {
        ...state,
        posts: updatedPostArray,
      };

    default:
      return state;
  }
};
