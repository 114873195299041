import { getReduxSubscribedUser } from "components/redux/actions/paymentAction";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import streamEight from "../../assets/img/dummy.jpg";
import { Button, Card, Col, Media, Progress, Row } from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";

const Notifications = ({ notiOpen }) => {
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [tipUsers, setTipUsers] = useState([]);
  const [subscribedByReciever, setSubscribedByReciever] = useState([]);
  const history = useHistory();
  const getCalls = useSelector((state) => state?.getAllCallReducer?.call);
  const subscribedUsers = useSelector(
    (state) => state?.getAllSenderRequestReducer
  );
  const subscribedData = subscribedUsers?.senderAllRequests;
  const AllTipUsers = useSelector((state) =>
    getReduxSubscribedUser(state?.getAllTip, userId?.id)
  );

  useEffect(() => {
    setSubscribedByReciever(
      subscribedData?.filter((data) => data?.paymentData?.notiStatus === true)
    );
  }, [subscribedData]);
  useEffect(() => {
    setTipUsers(
      AllTipUsers?.filter((data) => data?.paymentData?.notiStatus === true)
    );
  }, []);

  AOS.init();
  return (
    <>
      {notiOpen === true && (
        <div className="home-noti-div" data-aos="fade-down">
          <h3 className="home-noti-head">Notifications</h3>

          <Progress className="horizontal-progress-bar-menu" now={0} />
          <Progress className="vertical-progress-bar-menu" now={0} />
          <span style={{ position: "absolute", top: "0.3em" }}>
            <span
              style={{
                display: "inline-block",
                width: "1.0em",
                marginLeft: "2.5em",
                marginTop: "3.3em",
                height: "1.0em",
                borderRadius: "50%",
                backgroundColor: "white",
              }}
            ></span>
          </span>

          <div className="home-main-noti-div">
            {subscribedByReciever?.map((data) => (
              <>
                <Media left>
                  <img
                    object
                    src={
                      data?.userData?.profilePic
                        ? data?.userData?.profilePic
                        : streamEight
                    }
                    alt="jannan"
                    className=" chat-noti-profile rounded-circle"
                  />
                </Media>
                <Media body className="ml-3 mt-2 mb-5" data-aos="fade-right">
                  <h4
                    className="text-white chat-noti-profile-name  mb-0"
                    style={{ fontWeight: "600" }}
                  >
                    {data?.userData?.username
                      ? data?.userData?.username
                      : data?.userData?.firstName}
                  </h4>
                  <Card className="chat-noti-card mr-2 ">
                    <div style={{ display: "flex" }}>
                      <div>
                        <p className="chat-noti-text">
                          {data?.userData?.username
                            ? data?.userData?.username
                            : data?.userData?.firstName}{" "}
                          Bought your subscription
                        </p>
                      </div>
                    </div>
                  </Card>
                </Media>
                <hr
                  className="ml-5 mr-3"
                  style={{ backgroundColor: "#666363" }}
                />
              </>
            ))}
            {tipUsers?.map((data) => (
              <>
                <Media left>
                  <img
                    object
                    src={
                      data?.paymentData?.senderData?.profilePic
                        ? data?.paymentData?.senderData?.profilePic
                        : streamEight
                    }
                    alt="jannan"
                    className=" chat-noti-profile rounded-circle"
                  />
                </Media>
                <Media body className="ml-3 mt-2 mb-5" data-aos="fade-right">
                  <h4
                    className="text-white chat-noti-profile-name  mb-0"
                    style={{ fontWeight: "600" }}
                  >
                    {data?.paymentData?.senderData?.username
                      ? data?.paymentData?.senderData?.username
                      : data?.paymentData?.senderData?.firstName}
                  </h4>
                  <Card className="chat-noti-card mr-2 ">
                    <div style={{ display: "flex" }}>
                      <div>
                        <p className="chat-noti-text">
                          {data?.paymentData?.tip}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Media>
                <hr
                  className="ml-5 mr-3"
                  style={{ backgroundColor: "#666363" }}
                />
              </>
            ))}
            {getCalls?.map((data) => (
              <>
                <Media left>
                  <img
                    object
                    src={data?.profilePic ? data?.profilePic : streamEight}
                    alt="jannan"
                    className=" chat-noti-profile rounded-circle"
                  />
                </Media>
                <Media body className="ml-3 mt-2 mb-5" data-aos="fade-right">
                  <h4
                    className="text-white chat-noti-profile-name  mb-0"
                    style={{ fontWeight: "600" }}
                  >
                    {data?.username ? data?.username : data?.firstName}
                  </h4>
                  <Card className="chat-noti-card mr-2 ">
                    <div style={{ display: "flex" }}>
                      <div>
                        <p className="chat-noti-text">
                          Missed a call from{" "}
                          {data?.username ? data?.username : data?.firstName}{" "}
                        </p>
                        <Row>
                          <Col xl={12}>
                            <Button
                              onClick={() =>
                                history.push(`/admin/profile/${data?._id}`)
                              }
                              className="reset-button"
                            >
                              View Profile
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </Media>
                <hr
                  className="ml-5 mr-3"
                  style={{ backgroundColor: "#666363", marginTop: "30%" }}
                />
              </>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Notifications;
