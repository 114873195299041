import { pollcounterIncrement } from "Api/Api";
import { incrementCounter } from "components/redux/actions/postActions";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Progress, Row } from "reactstrap";
import { BsDot } from "react-icons/bs";
import "./Poll.css";
import pollOne from "../../assets/img/dummy.jpg";

const Poll = (props) => {
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [voteCheck, setVoteCheck] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    props?.data?.userPollId?.map((data) => {
      if (data === userId.id) {
        setVoteCheck(true);
      }
    });
  }, [userId]);
  const totalVotes = props?.data?.userPollId?.length;
  const handleIncrement = (
    objectId,
    objectContainingCounterId,
    counterValue
  ) => {
    console.log(objectId, objectContainingCounterId, counterValue);
    dispatch(
      incrementCounter(objectId, objectContainingCounterId, counterValue)
    );
    const values = {
      userId: userId.id,
      objectId: objectId,
      objectContainingCounterId: objectContainingCounterId,
      counterValue: counterValue,
    };
    pollcounterIncrement(values);
    setVoteCheck(true);
  };

  

  return (
    <div className="mb-5">
      <div className="card second-card-main ml-lg-3 mt-5">
        <img
          src={
            props.data?.userData?.profilePic
              ? props.data?.userData?.profilePic
              : pollOne
          }
          className="card-img-top rounded-circle"
          alt="..."
          onClick={() => history.push(`/admin/profile/${props?.data?.userId}`)}
        />

        <div class="card-body poll-card ">
          <h3
            className="text-white ml-3 mt-5 mb-0"
            style={{ fontWeight: "700" }}
          >
            {props?.data?.question} ?
          </h3>
          <h4 className="text-white ml-lg-3 mt-1">
            You can see how people vote.
          </h4>
          {voteCheck === false &&
            props?.data?.options?.map((data, index) => (
              <Row className="">
                <Col className="text-center">
                  <Button
                    className="poll-option"
                    onClick={() =>
                      handleIncrement(
                        props?.data?._id,
                        data?._id,
                        data?.counter
                      )
                    }
                  >
                    {data?.value}
                  </Button>
                </Col>
              </Row>
            ))}

          {voteCheck === true &&
            props?.data?.options?.map((data, index) => (
              <Row className="mt-2" key={data}>
                <Col xl={8}>
                  <h4 className="text-white">{data.value}</h4>
                  <Progress
                    animated
                    value={data?.counter}
                    className="progress-bars "
                  >
                    {`${data?.counter}%`}
                  </Progress>
                </Col>
                <Col>
                  <h4
                    className="text-center text-white mt-5"
                    style={{ fontWeight: "700" }}
                  >
                    {data?.counter}%
                  </h4>
                </Col>
              </Row>
            ))}
          <h5 className="text-white ml-lg-3 mt-2" style={{ fontWeight: "700" }}>
            {totalVotes} votes
            {voteCheck === false && (
              <>
                <span>
                  <BsDot style={{ color: "white", fontSize: "30px" }} />{" "}
                </span>
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => setVoteCheck(true)}
                >
                  View results
                </span>
              </>
            )}
          </h5>
        </div>

        <div class="card-footer bg-transparent d-flex justify-content-end mb-1"></div>
      </div>
    </div>
  );
};

export default Poll;
