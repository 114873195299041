export const postLoadingAnimation = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
      }}
    >
      <lottie-player
        src="https://assets1.lottiefiles.com/packages/lf20_YKljgC7Siv.json"
        background="transparent"
        speed="1"
        style={{ width: "300px", height: "300px" }}
        loop
        autoplay
      ></lottie-player>
    </div>
  );
};
export const AddAnimation = () => {
  return (
    <div className="ml-2 add-icon">
      <lottie-player
        className=""
        src="https://lottie.host/20d6b927-0284-46bd-ba67-64d2ec3e01e2/i7cKAD2EdT.json"
        background="transparent"
        speed="1"
        style={{ width: "50px", height: "35px" }}
        loop
        autoplay
      ></lottie-player>
    </div>
  );
};
export const ActionAnimation = () => {
  return (
    <lottie-player  src="https://assets6.lottiefiles.com/packages/lf20_vpxae5vy.json"  background="transparent"  speed="1"  style={{width: "100px", height: "100px"}}  loop  autoplay></lottie-player>
  );
};
export const FeeAnimation = () => {
  return (
    <lottie-player className="mr-lg-5"  src="https://assets5.lottiefiles.com/packages/lf20_bogmlqx0.json"  background="transparent"  speed="1"  style={{width: "150px", height: "150px"}}  loop  autoplay></lottie-player>
  );
};
export const CreatorAnimation = () => {
  return (
    <lottie-player className="mr-lg-5"  src="https://assets7.lottiefiles.com/private_files/lf30_pljwgbzs.json"  background="transparent"  speed="1"  style={{width: "150px", height: "150px"}}  loop  autoplay></lottie-player>
  );
};
