export const GET_POSTS_SUCCESS = 'getPostsSuccess'
export const GET_POSTS_FAIL = 'getPostsFail'
export const GET_Ads_SUCCESS = 'getAdsSuccess'
export const GET_PAYMENT_SUCCESS = 'getpaymentSuccess'
export const GET_PAYMENT_FAIL = 'getpaymentfail'
export const GET_PAYMENT_REQUEST_SUCCESS = 'getpaymentrequestSuccess'
export const GET_PAYMENT_REQUEST_FAIL = 'getpaymentrequestfail'
export const GET_TIP_SUCCESS = 'getTipSuccess'
export const GET_TIP_FAIL = 'getTipFail'
export const GET_Ads_FAIL = 'getAdsFail'
export const GET_USER_SUCCESS = 'getUserSuccess'
export const GET_USER_FAIL = 'getUserFail'
export const GET_ALL_USER_SUCCESS = 'getAllUserSuccess'
export const GET_ALL_USER_FAIL = 'getAllUserFail'
export const GET_ALL_REQUEST_SUCCESS = 'getAllRequestSuccess'
export const GET_ALL_REQUEST_FAIL = 'getAllRequestFail'
export const GET_ALL_ACCEPTED_REQUEST_SUCCESS = 'getAllAccpetedRequestSuccess'
export const GET_ALL_ACCEPTED_REQUEST_FAIL = 'getAllAcceptedRequestFail'
export const GET_ALL_SENDER_REQUEST_SUCCESS = 'getAllSenderRequestSuccess'
export const GET_ALL_SENDER_REQUEST_FAIL = 'getAllSenderRequestFail'
export const GET_LIST_SUCCESS = 'getAllListSuccess'
export const GET_LIST_FAIL = 'getAllListFail'
export const DELETE_LIST_SUCCESS = 'deleteListSuccess'
export const DELETE_LIST_FAIL = 'deleteAllListFail'
export const Poll_Counter_Increment = 'pollCounterIncrement'
export const GET_ALL_CALL_SUCCESS = 'getAllCallSuccess'
export const GET_ALL_CALL_FAIL = 'getAllCalltFail'
export const GET_ALL_CREATOR_REQUEST_SUCCESS = 'getAllCreatorRequestSuccess'
export const GET_ALL_CREATOR_REQUEST_FAIL = 'getAllCreatorRequetFail'
export const GET_ALL_SUBSCRIPTIONS_SUCCESS = 'getAllSubscriptionsSuccess'
export const GET_ALL_SUBSCRIPTIONS_FAIL = 'getAllSubscriptionsFail'
export const GET_CONTACT_SUCCESS = 'getAllContactSuccess'
export const GET_CONTACT_FAIL = 'getAllContactFail'
export const ADD_USER_TO_POST = 'AddUserToPost'
export const ADD_USER_TO_SUBSCRIBE = 'AddUserToSubscribe'


