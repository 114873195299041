import ChatPortion from "components/ChatPortion/ChatPortion";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row } from "reactstrap";
import postOne from "../../assets/img/j46.png";
import "./post.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileBase64 from "react-file-base64";
import { createAd } from "Api/Api";
import { getUserById } from "components/redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { showSuccessAlert } from "components/Alerts/Alerts";
import { uploadImageToFirebase } from "components/DynamicFuntions";
import { showErrorAlert } from "components/Alerts/Alerts";
import { showWarnAlert } from "components/Alerts/Alerts";
import UserCheck from "components/UserCheck";
import { ActionAnimation } from "components/LottieAnimation/LottieAnimations";
import { countries } from "mock_data/listing";
import { cityList } from "mock_data/listing";

const CreateAd = () => {
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [availableFor, setAvailableFor] = useState("");
  const [time, setTime] = useState();
  const [date, setDate] = useState();
  const [gender, setGender] = useState("");
  const [meetingType, setMeetingType] = useState("");
  const [description, setDescription] = useState("");
  const [adPic, setAdPic] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [adress, setAdress] = useState("");
  const [province, setProvince] = useState("");
  const [age, setAge] = useState();
  const [animationCheck, setAnimationCheck] = useState(false);
  const getUser = useSelector((state) => state?.getUserById);
  const userData = getUser?.userData;
  const history = useHistory();
  const Values = {
    userId: userId.id,
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserById(Values));
  }, []);
  const handleAdPic = (e) => {
    setAdPic(e.selectedFile.base64);
  };
  

  const ad = async (e) => {
    e.preventDefault();
    setAnimationCheck(true);

    if (
      country !== "" &&
      gender !== "" &&
      city !== "" &&
      time !== "" &&
      date !== "" &&
      meetingType !== "" &&
      adPic !== ""
    ) {
      const result = await uploadImageToFirebase(adPic);
      if (result) {
        setAdPic(result);

        // Use the image URL in an <img> tag
        const values = {
          userId: userId.id,
          availableFor: availableFor,
          time: time,
          date: date,
          gender: gender,
          meetingType: meetingType,
          description: description,
          adPic: result,
          userData: userData,
          adress: adress,
          country: country,
          city: city,
          age: age,
          province: province,
        };
        await createAd(values).then((res) => {
          if (res.data.message === "ad Generated") {
            showSuccessAlert("Ad Created");

            setTimeout(() => {
              history.push("/admin/home");
            }, 2000);
          } else {
            showErrorAlert("Server error");

            setAnimationCheck(false);
          }
        });
      }
    } else {
      showWarnAlert("'please select all fields");

      setAnimationCheck(false);
    }
  };

  return (
    <div className="content">
      <Row>
        {userData?.creator === true && userData?.eroxrFee === true && (
          <Col xl={8} className="text-center">
            <h2
              className="text-center text-white"
              style={{ fontWeight: "600" }}
            >
              Create Ad <br />
              For Your Meeting
            </h2>
            <Card
              className=" "
              style={{
                backgroundColor: "#1e1e2b",
                borderRadius: "4px",
                border: "1px solid white",
              }}
            >
              <h1 className="mt-5 mb-5">
                <div
                  style={{ opacity: "0", position: "absolute", zIndex: "10" }}
                >
                  <FileBase64
                    type="file"
                    className="text-center"
                    onDone={(base64) => handleAdPic({ selectedFile: base64 })}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <img
                  src={adPic ? adPic : postOne}
                  style={{ width: adPic && "80%", zIndex: "5" }}
                  alt="Ad"
                />
              </h1>
            </Card>
            <Form onSubmit={ad}>
              <Input
                id="exampleEmail"
                placeholder=" I am avaialable for..."
                type="text"
                className="post-input"
                onChange={(e) => setAvailableFor(e.target.value)}
                required
              />
              <Input
                id="exampleEmail"
                placeholder="Adress"
                type="text"
                className="post-input mt-4"
                onChange={(e) => setAdress(e.target.value)}
                required
              />
              <Row className="mt-4">
                <Col>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    className="post-input ad-option"
                    // placeholder='Gender'
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  >
                    <option>Country</option>
                    {countries?.map((data) => (
                      <option>{data?.name}</option>
                    ))}
                  </Input>
                </Col>
                <Col>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    className="post-input ad-option"
                    placeholder="Meeting Type"
                    onChange={(e) => setCity(e.target.value)}
                    required
                  >
                    <option>City</option>
                    {cityList?.map((data) => (
                      <option className="">{data?.name}</option>
                    ))}
                  </Input>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col>
                  <Input
                    id="exampleEmail"
                    name="datetime"
                    placeholder="Month,Date"
                    type="date"
                    className="post-input "
                    onChange={(e) => setDate(e.target.value)}
                  />
                </Col>
                <Col>
                  <Input
                    id="exampleSelect"
                    name="select"
                    placeholder="Time"
                    type="time"
                    className="post-input"
                    onChange={(e) => setTime(e.target.value)}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col>
                  <Input
                    id="exampleEmail"
                    name="age"
                    placeholder="Age"
                    type="number"
                    className="post-input "
                    onChange={(e) => setAge(e.target.value)}
                    required
                  />
                </Col>
                <Col>
                  <Input
                    id="exampleSelect"
                    name="province"
                    placeholder="Province"
                    type="text"
                    className="post-input"
                    onChange={(e) => setProvince(e.target.value)}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    className="post-input"
                    placeholder="Gender"
                    onChange={(e) => setGender(e.target.value)}
                    required
                  >
                    <option>Gender</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                  </Input>
                </Col>
                <Col>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    className="post-input"
                    placeholder="Meeting Type"
                    onChange={(e) => setMeetingType(e.target.value)}
                    required
                  >
                    <option>Meeting Type</option>
                    <option>Date</option>
                    <option>One night</option>
                    <option>Casual</option>
                    <option>Fan</option>
                  </Input>
                </Col>
              </Row>
              <Input
                id="exampleText"
                name="text"
                type="textarea"
                placeholder="Description"
                className="post-text mt-4"
                onChange={(e) => setDescription(e.target.value)}
                required
              />
              <Row className="justify-content-end mt-3">
                {animationCheck ? (
                    ActionAnimation()
                  ) : (
                  <Button type="submit" className="reset-button mr-2">
                    Save
                  </Button>
                )}
              </Row>
            </Form>
          </Col>
        )}
        <UserCheck userData={userData} />

        <Col xl={4}>
          <ChatPortion />
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default CreateAd;
