
import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
} from "variables/charts.js";
import { Product } from "backend-sdk/product.sdk";
import { Order } from "backend-sdk/order.sdk";
import { Article } from "backend-sdk/article.sdk";
import { Task } from "backend-sdk/task.sdk";
import { LineChartView } from "backedComponents/LineChart/LineChartView";
import { LineChart } from "backedComponents/LineChart/LineChart";
import { BarChartView } from "backedComponents/BarChart/BarChartView";
import { BarChart } from "backedComponents/BarChart/BarChart";
import { SimpleTableView } from "backedComponents/SimpleTable/SimpleTableView";
import { SimpleTable } from "backedComponents/SimpleTable/SimpleTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllCreatorRequest } from "components/redux/actions/creatorActions";
import { getAllUsers } from "components/redux/actions/userActions";
import { getPayments } from "components/redux/actions/paymentAction";
import { getAllSubscriptions } from "components/redux/actions/requestActions";
import { getAllTip } from "components/redux/actions/paymentAction";
import { getPaymentsRequest } from "components/redux/actions/paymentAction";


function Dashboard(props) {
  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  const [chartData1, setChartData1] = React.useState(undefined);
  const [chartData2, setChartData2] = React.useState(undefined);
  const [chartData3, setChartData3] = React.useState(undefined);
  const [chartData4, setChartData4] = React.useState(undefined);
  const [chartRef1,setChartRef1] = React.useState(null);
  const [chartRef2,setChartRef2] = React.useState(null);
  const [chartRef3,setChartRef3] = React.useState(null);
  const [chartRef4,setChartRef4] = React.useState(null);

 const dispatch=useDispatch()
 useEffect(() => {
     
      dispatch(getAllCreatorRequest())
      dispatch(getAllUsers())
      dispatch(getPayments())
      dispatch(getAllSubscriptions())
      dispatch(getAllTip())
      dispatch(getPaymentsRequest())
    
        
    }, [dispatch])
  const getAllUser= useSelector(state => state?.getAllUsers);
    const AllUser=getAllUser?.allUsers
    const getAllPayment= useSelector(state => state?.getPaymentReducer);
    const payment=getAllPayment?.payment

  // function to retrieve data from the database in a format suitable for chart 1
  
  // React.useEffect(() => {
  //   async function getChartData1() {
  //     const canvas = chartRef1;
  //     const data = await Task.getChartData(localStorage.getItem("apiToken"));
  //     if (!data.success) {
  //       console.log("error at get chart data");
  //       setChartData1("Problem");
  //     } else { 
         
  //       let ctx = canvas.ctx;

  //       let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

  //       gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
  //       gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
  //       gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

  //       var labels = data.labels;
  //       var chartData = data.data;
  //       setChartData1({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: "Tasks",
  //             fill: true,
  //             backgroundColor: gradientStroke,
  //             borderColor: "#1f8ef1",
  //             borderWidth: 2,
  //             borderDash: [],
  //             borderDashOffset: 0.0,
  //             pointBackgroundColor: "#1f8ef1",
  //             pointBorderColor: "rgba(255,255,255,0)",
  //             pointHoverBackgroundColor: "#1f8ef1",
  //             pointBorderWidth: 20,
  //             pointHoverRadius: 4,
  //             pointHoverBorderWidth: 15,
  //             pointRadius: 4,
  //             data: chartData,
  //           },
  //         ],
  //       });
  //     }
  //   }
  //   if (!chartData1 && chartRef1) {
  //     getChartData1();
  //   }
  // }, [chartData1, chartRef1]); 

 

  const tableDataHeaders = ["name","country","city","salary"]
  const TableData = [
      {
          name: "Dakota Rice",
          country: "Niger",
          city: "Oud-Turnhout",
          salary: "$36,738"
      },
      {
          name: "Minerva Hooper",
          country: "Curaçao",
          city: "Sinaai-Waas",
          salary: "$23,789"
      },
      {
          name: "Sage Rodriguez",
          country: "Netherlands",
          city: "Baileux",
          salary: "$56,142"
      },
      {
          name: "Philip Chaney",
          country: "Korea, South",
          city: "Overland Park",
          salary: "$38,735"
      },
  ] 
 

  return (
    <>
      <div className="content">
        <Row>
        <h2 className="ml-lg-3">Dashboard</h2>
        {
          // <Col xs="12">
          //   <Card className="card-chart">
          //     <CardHeader>
          //       <Row>
          //         <Col className="text-left" sm="6">
          //           <h5 className="card-category">All Tasks</h5>
          //           <CardTitle tag="h2">Performance</CardTitle>
          //         </Col>
          //         <Col sm="6">
          //           <ButtonGroup
          //             className="btn-group-toggle float-right"
          //             data-toggle="buttons"
          //           >
          //             <Button
          //               tag="label"
          //               className={classNames("btn-simple", {
          //                 active: bigChartData === "data1",
          //               })}
          //               color="info"
          //               id="0"
          //               size="sm"
          //               onClick={() => setBgChartData("data1")}
          //             >
          //               <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
          //                 Accounts
          //               </span>
          //               <span className="d-block d-sm-none">
          //                 <i className="tim-icons icon-single-02" />
          //               </span>
          //             </Button>
          //             <Button
          //               color="info"
          //               id="1"
          //               size="sm"
          //               tag="label"
          //               className={classNames("btn-simple", {
          //                 active: bigChartData === "data2",
          //               })}
          //               onClick={() => setBgChartData("data2")}
          //             >
          //               <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
          //                 Purchases
          //               </span>
          //               <span className="d-block d-sm-none">
          //                 <i className="tim-icons icon-gift-2" />
          //               </span>
          //             </Button>
          //             <Button
          //               color="info"
          //               id="2"
          //               size="sm"
          //               tag="label"
          //               className={classNames("btn-simple", {
          //                 active: bigChartData === "data3",
          //               })}
          //               onClick={() => {
          //                 setBgChartData("data3");
          //               }}
          //             >
          //               <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
          //                 Sessions
          //               </span>
          //               <span className="d-block d-sm-none">
          //                 <i className="tim-icons icon-tap-02" />
          //               </span>
          //             </Button>
          //           </ButtonGroup>
          //         </Col>
          //       </Row>
          //     </CardHeader>
          //     <CardBody>
          //       <div className="chart-area">
          //         <Line
          //           data={chartData1}
          //           options={chartExample1.options}
          //           ref={newRef => setChartRef1(newRef)}
          //         />
          //       </div>
          //     </CardBody>
          //   </Card>
          // </Col>
                      }
        </Row>
        <Row>
          <Col lg="4">
            <LineChartView 
              data = {chartExample2.data}
              options = {chartExample2.options}
              passedRef = {newRef => setChartRef2(newRef)}
              dataName = {"Total Users"}
              totalData = {AllUser?.length}
            />
          </Col>
          <Col lg="4">
          <LineChartView 
              data = {chartExample2.data}
              options = {chartExample2.options}
              passedRef = {newRef => setChartRef2(newRef)}
              dataName = {"Total Payments"}
              totalData = {payment.length}
            />
            
          </Col>
          <Col lg="4">
            <BarChartView
              data = {chartExample3.data}
              options = {chartExample3.options}
              passedRef = {newRef => setChartRef3(newRef)}
              dataName = {"Eroxr Revenue"}
              totalData = {"SEK" + " "+payment.length*100}
            />
          </Col>
          {
          // <Col lg="4">
          //   <BarChart
          //     class = {Product}
          //     className = {"Products"}
          //     dataName = {"All Products - backend call"}
          //   />
          // </Col>
          // <Col lg="4">
          //   <Card className="card-chart">
          //     <CardHeader>
          //       <h5 className="card-category">All Articles</h5>
          //       <CardTitle tag="h3">
          //         <i className="tim-icons icon-send text-success" /> 1759
          //       </CardTitle>
          //     </CardHeader>
          //     <CardBody>
          //       <div className="chart-area">
          //         <Line
          //           data={chartExample4.data}
          //           options={chartExample4.options}
          //           ref={newRef => setChartRef4(newRef)}
          //         />
          //       </div>
          //     </CardBody>
          //   </Card>
          // </Col>
          }
        </Row>
        {
        // <Row>
        
        //   <Col lg="6" md="12">
        //     <SimpleTable class ={Article}/>
        //   </Col>
        //   <Col lg="6" md="12">
        //     <Card className="card-tasks">
        //       <CardHeader>
        //         <h6 className="title d-inline">Tasks(5)</h6>
        //         <p className="card-category d-inline"> today</p>
        //         <UncontrolledDropdown>
        //           <DropdownToggle
        //             caret
        //             className="btn-icon"
        //             color="link"
        //             data-toggle="dropdown"
        //             type="button"
        //           >
        //             <i className="tim-icons icon-settings-gear-63" />
        //           </DropdownToggle>
        //           <DropdownMenu aria-labelledby="dropdownMenuLink" right>
        //             <DropdownItem
        //               href="#pablo"
        //               onClick={(e) => e.preventDefault()}
        //             >
        //               Action
        //             </DropdownItem>
        //             <DropdownItem
        //               href="#pablo"
        //               onClick={(e) => e.preventDefault()}
        //             >
        //               Another action
        //             </DropdownItem>
        //             <DropdownItem
        //               href="#pablo"
        //               onClick={(e) => e.preventDefault()}
        //             >
        //               Something else
        //             </DropdownItem>
        //           </DropdownMenu>
        //         </UncontrolledDropdown>
        //       </CardHeader>
        //       <CardBody>
        //         <div className="table-full-width table-responsive">
        //           <Table>
        //             <tbody>
        //               <tr>
        //                 <td>
        //                   <FormGroup check>
        //                     <Label check>
        //                       <Input defaultValue="" type="checkbox" />
        //                       <span className="form-check-sign">
        //                         <span className="check" />
        //                       </span>
        //                     </Label>
        //                   </FormGroup>
        //                 </td>
        //                 <td>
        //                   <p className="title">Update the Documentation</p>
        //                   <p className="text-muted">
        //                     Dwuamish Head, Seattle, WA 8:47 AM
        //                   </p>
        //                 </td>
        //                 <td className="td-actions text-right">
        //                   <Button
        //                     color="link"
        //                     id="tooltip636901683"
        //                     title=""
        //                     type="button"
        //                   >
        //                     <i className="tim-icons icon-pencil" />
        //                   </Button>
        //                   <UncontrolledTooltip
        //                     delay={0}
        //                     target="tooltip636901683"
        //                     placement="right"
        //                   >
        //                     Edit Task
        //                   </UncontrolledTooltip>
        //                 </td>
        //               </tr>
        //               <tr>
        //                 <td>
        //                   <FormGroup check>
        //                     <Label check>
        //                       <Input
        //                         defaultChecked
        //                         defaultValue=""
        //                         type="checkbox"
        //                       />
        //                       <span className="form-check-sign">
        //                         <span className="check" />
        //                       </span>
        //                     </Label>
        //                   </FormGroup>
        //                 </td>
        //                 <td>
        //                   <p className="title">GDPR Compliance</p>
        //                   <p className="text-muted">
        //                     The GDPR is a regulation that requires businesses to
        //                     protect the personal data and privacy of Europe
        //                     citizens for transactions that occur within EU
        //                     member states.
        //                   </p>
        //                 </td>
        //                 <td className="td-actions text-right">
        //                   <Button
        //                     color="link"
        //                     id="tooltip457194718"
        //                     title=""
        //                     type="button"
        //                   >
        //                     <i className="tim-icons icon-pencil" />
        //                   </Button>
        //                   <UncontrolledTooltip
        //                     delay={0}
        //                     target="tooltip457194718"
        //                     placement="right"
        //                   >
        //                     Edit Task
        //                   </UncontrolledTooltip>
        //                 </td>
        //               </tr>
        //               <tr>
        //                 <td>
        //                   <FormGroup check>
        //                     <Label check>
        //                       <Input defaultValue="" type="checkbox" />
        //                       <span className="form-check-sign">
        //                         <span className="check" />
        //                       </span>
        //                     </Label>
        //                   </FormGroup>
        //                 </td>
        //                 <td>
        //                   <p className="title">Solve the issues</p>
        //                   <p className="text-muted">
        //                     Fifty percent of all respondents said they would be
        //                     more likely to shop at a company
        //                   </p>
        //                 </td>
        //                 <td className="td-actions text-right">
        //                   <Button
        //                     color="link"
        //                     id="tooltip362404923"
        //                     title=""
        //                     type="button"
        //                   >
        //                     <i className="tim-icons icon-pencil" />
        //                   </Button>
        //                   <UncontrolledTooltip
        //                     delay={0}
        //                     target="tooltip362404923"
        //                     placement="right"
        //                   >
        //                     Edit Task
        //                   </UncontrolledTooltip>
        //                 </td>
        //               </tr>
        //               <tr>
        //                 <td>
        //                   <FormGroup check>
        //                     <Label check>
        //                       <Input defaultValue="" type="checkbox" />
        //                       <span className="form-check-sign">
        //                         <span className="check" />
        //                       </span>
        //                     </Label>
        //                   </FormGroup>
        //                 </td>
        //                 <td>
        //                   <p className="title">Release v2.0.0</p>
        //                   <p className="text-muted">
        //                     Ra Ave SW, Seattle, WA 98116, SUA 10:19 AM
        //                   </p>
        //                 </td>
        //                 <td className="td-actions text-right">
        //                   <Button
        //                     color="link"
        //                     id="tooltip818217463"
        //                     title=""
        //                     type="button"
        //                   >
        //                     <i className="tim-icons icon-pencil" />
        //                   </Button>
        //                   <UncontrolledTooltip
        //                     delay={0}
        //                     target="tooltip818217463"
        //                     placement="right"
        //                   >
        //                     Edit Task
        //                   </UncontrolledTooltip>
        //                 </td>
        //               </tr>
        //               <tr>
        //                 <td>
        //                   <FormGroup check>
        //                     <Label check>
        //                       <Input defaultValue="" type="checkbox" />
        //                       <span className="form-check-sign">
        //                         <span className="check" />
        //                       </span>
        //                     </Label>
        //                   </FormGroup>
        //                 </td>
        //                 <td>
        //                   <p className="title">Export the processed files</p>
        //                   <p className="text-muted">
        //                     The report also shows that consumers will not easily
        //                     forgive a company once a breach exposing their
        //                     personal data occurs.
        //                   </p>
        //                 </td>
        //                 <td className="td-actions text-right">
        //                   <Button
        //                     color="link"
        //                     id="tooltip831835125"
        //                     title=""
        //                     type="button"
        //                   >
        //                     <i className="tim-icons icon-pencil" />
        //                   </Button>
        //                   <UncontrolledTooltip
        //                     delay={0}
        //                     target="tooltip831835125"
        //                     placement="right"
        //                   >
        //                     Edit Task
        //                   </UncontrolledTooltip>
        //                 </td>
        //               </tr>
        //               <tr>
        //                 <td>
        //                   <FormGroup check>
        //                     <Label check>
        //                       <Input defaultValue="" type="checkbox" />
        //                       <span className="form-check-sign">
        //                         <span className="check" />
        //                       </span>
        //                     </Label>
        //                   </FormGroup>
        //                 </td>
        //                 <td>
        //                   <p className="title">Arival at export process</p>
        //                   <p className="text-muted">
        //                     Capitol Hill, Seattle, WA 12:34 AM
        //                   </p>
        //                 </td>
        //                 <td className="td-actions text-right">
        //                   <Button
        //                     color="link"
        //                     id="tooltip217595172"
        //                     title=""
        //                     type="button"
        //                   >
        //                     <i className="tim-icons icon-pencil" />
        //                   </Button>
        //                   <UncontrolledTooltip
        //                     delay={0}
        //                     target="tooltip217595172"
        //                     placement="right"
        //                   >
        //                     Edit Task
        //                   </UncontrolledTooltip>
        //                 </td>
        //               </tr>
        //             </tbody>
        //           </Table>
        //         </div>
        //       </CardBody>
        //     </Card>
        //   </Col>
        //   <Col lg="6" md="12">
        //     <SimpleTableView dataHeaders = {tableDataHeaders} data = {TableData}/>
        //   </Col>
       
        // </Row>
        }
      </div>
    </>
  );
}

export default Dashboard;
