import React from "react";
import profilePic from "../../assets/img/dummy.jpg";
import { useHistory } from "react-router-dom";
import { checkPost } from "components/DynamicFuntions";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Row } from "reactstrap";
import { AiOutlineUserAdd } from "react-icons/ai";
import { Elements } from "@stripe/react-stripe-js";
import PaymentModal from "components/Modals/PaymentModal";
import stripePromise from "Api/Stripe";
import { addList } from "Api/Api";
import { ToastContainer, toast } from "react-toastify";
import LockModal from "components/Modals/LockModal";
import { AddAnimation } from "components/LottieAnimation/LottieAnimations";

const CustomCard = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const [lockModal, setLockModal] = useState(false);
  const history = useHistory();
  const getSubscribedUser = useSelector(
    (state) => state?.getAllAcceptedRequestReducer?.accpetedRequests
  );
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [animationTwoCheck, setAnimationTwoCheck] = useState(false);
  const addToList = (id) => {
    const values = {
      otherId: id,
      userId: userId.id,
    };

    addList(values).then((data) => {
      if (data?.data?.message === "user added") {
        setAnimationTwoCheck(true);
        setTimeout(() => {
          setAnimationTwoCheck(false);
        }, 5000);
      }
      if (data?.data?.message === "user already added") {
        toast.error("Already Added", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,

          theme: "dark",
        });
      }
    });
  };
  
  return (
    <div className="card card-main">
      <div className="card-content-container">
        <img
          src={
            data?.userData?.profilePic ? data?.userData?.profilePic : profilePic
          }
          className="card-img-top rounded-circle"
          alt="..."
          onClick={() => history.push(`/admin/profile/${data.userId}`)}
        />

        <div className="card-body">
          <div
            style={{
              width: "100%",
              height: "450px",
              borderRadius: "40px",
              overflow: "hidden",
            }}
          >
            {checkPost(data, getSubscribedUser, userId)}
            {data.subscriberCheck === false && userId?.id !== data?.userId && (
              <>
                {getSubscribedUser?.length &&
                getSubscribedUser?.some(
                  (datas) => datas?._id === data?.userId
                ) ? (
                  <></>
                ) : (
                  <>
                    {data?.payerId.length &&
                    data?.payerId.includes(userId?.id) ? (
                      <></>
                    ) : (
                      <>
                        {data?.postCheck === true && (
                          <Elements stripe={stripePromise}>
                            <LockModal open={lockModal} value={data} />
                          </Elements>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {userId?.id !== data?.userId && (
            <Row className="justify-content-end">
              <div className="card-footer card-footers bg-transparent d-flex  mb-1">
                {animationTwoCheck === true && AddAnimation()}
                {animationTwoCheck === false && (
                  <AiOutlineUserAdd
                    className="ml-2 add-icon"
                    onClick={() => addToList(data?.userId)}
                  />
                )}

                <Elements stripe={stripePromise}>
                  <PaymentModal value={data} />
                </Elements>
              </div>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CustomCard;
