import React from "react";
import "./LandingPage.css";
import Slider from "components/Slider/Slider";
import { Accordion } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import rounded from "../../assets/img/newone.png";
import landingOne from "../../assets/img/newnine.png";
import landingTwo from "../../assets/img/newEight.png";

import Aos from "aos";

const LandingPage = () => {
  const history = useHistory();
  Aos.init();

  return (
    <div className="">
      <br />
      <div className="landing-div">
        <h1 className="landing-heading text-center">
          Earn & Connect with <br /> your most loyal fans
        </h1>
        <p className="landing-subheading text-center">
          Monetize your following by creating clean, exclusive,
          <br />
          behind-the-scenes content on Eroxr.
        </p>
        <h1 className="text-center">
          <button
            className="btn btn-lg landing-button"
            onClick={() => history.push("/login")}
          >
            <span className="gradient-text">Apply As Creator on Eroxr</span>
          </button>
        </h1>
        <Slider />
        <div
          className="tip-section mt-2"
          data-aos="fade-up"
          data-aos-delay="5000"
        >
          <h1 className="landing-heading text-center">
            CONNECT WITH YOUR FANS
            <br /> AND GET PAID DOING IT
          </h1>
          <p className="landing-subheading text-center">
            Set your own pay-per-message price and tips on posts.
          </p>
          <div className="tips-block">
            <img
              src={rounded}
              loading="lazy"
              alt=""
              className="tips-block-img"
              data-aos="fade-right"
              data-aos-delay="1000"
            />

            <div className="tips-block-inner">
              <img
                src="https://assets-global.website-files.com/62c48d78ef34931f8a604ef5/62c85fd50b60d4d8f880bc99_card-1-blurred.png"
                loading="lazy"
                sizes="(max-width: 479px) 300px, 359px"
                srcset="https://assets-global.website-files.com/62c48d78ef34931f8a604ef5/62c85fd50b60d4d8f880bc99_card-1-blurred-p-500.png 500w, https://assets-global.website-files.com/62c48d78ef34931f8a604ef5/62c85fd50b60d4d8f880bc99_card-1-blurred.png 718w"
                alt=""
                className="tip-block-inner-img-one"
              />

              <img
                src={landingTwo}
                loading="lazy"
                sizes="(max-width: 479px) 300px, 359px"
                srcset={landingTwo}
                alt=""
                className="tip-block-inner-img-two"
              />
            </div>

            <div
              className="tip-block-chats"
              data-aos="fade-left"
              data-aos-delay="500"
            >
              <img
                src="https://assets-global.website-files.com/62c48d78ef34931f8a604ef5/62c5c0b901a8726865637b13_chat-1.png"
                loading="lazy"
                width="335.5"
                sizes="(max-width: 991px) 180px, 335.5px"
                alt=""
                srcset="https://assets-global.website-files.com/62c48d78ef34931f8a604ef5/62c5c0b901a8726865637b13_chat-1-p-500.png 500w, https://assets-global.website-files.com/62c48d78ef34931f8a604ef5/62c5c0b901a8726865637b13_chat-1.png 671w"
                className="tip-block-chat-one"
              />
              <div
                className="tip-block-chat-two"
                data-aos="fade-right"
                data-aos-delay="1000"
              >
                <img
                  src="https://assets-global.website-files.com/62c48d78ef34931f8a604ef5/62c86178117e4a87731318b1_chat-2.png"
                  loading="lazy"
                  width="335.5"
                  alt=""
                />
              </div>
              <div
                className="tip-block-chat-three"
                data-aos="fade-right"
                data-aos-delay="1000"
              >
                <img
                  src="https://assets-global.website-files.com/62c48d78ef34931f8a604ef5/62c5c0b9e4a0283c3656d852_chat-3.png"
                  loading="lazy"
                  width="335.5"
                  sizes="(max-width: 991px) 180px, 335.5px"
                  alt=""
                  srcset="https://assets-global.website-files.com/62c48d78ef34931f8a604ef5/62c5c0b9e4a0283c3656d852_chat-3-p-500.png 500w, https://assets-global.website-files.com/62c48d78ef34931f8a604ef5/62c5c0b9e4a0283c3656d852_chat-3.png 655w"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="subscription-section"
          style={{ marginTop: "150px" }}
          data-aos="fade-up"
          data-aos-delay="5000"
        >
          <h1 className="landing-heading text-center">
            MONTHLY MEMBERSHIPS
            <br />
            FOR YOUR BIGGEST FANS
          </h1>
          <p className="landing-subheading text-center">
            Earn a recurring income by accepting monthly subscriptions for
            exclusive <br />
            behind-the-scenes content that your fans will love.
          </p>
          <div className="tips-block">
            <img
              src={landingOne}
              loading="lazy"
              alt=""
              className=""
              data-aos="fade-right"
              data-aos-delay="1000"
            />
          </div>
        </div>
      </div>
      <Row
        className="justify-content-center"
        style={{ marginTop: "150px" }}
        data-aos="fade-up"
        data-aos-delay="5000"
      >
        <Col xl={8} className="mb-5">
          <h1
            className="text-center mt-5 landing-heading"
            style={{ lineHeight: "120%" }}
          >
            Frequently Asked <br /> Qustions & Answers
          </h1>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" className="accordion-item">
              <Accordion.Header className="accordion-header">
                How many followers do I need to become a Eroxr Creator?
              </Accordion.Header>
              <Accordion.Body>
                You just need to pay Eroxr platform fee to become a creator.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header className="accordion-header">
                How much I earn?{" "}
              </Accordion.Header>
              <Accordion.Body>
                How much you earn is completely up to you! You can set your own
                subscription & People can give you tips based on your content.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header className="accordion-header">
                Is there any Age restrictions?{" "}
              </Accordion.Header>
              <Accordion.Body>
                yes, you have to be 18+ to use this platform.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header className="">
                Is explicit content allowed?
              </Accordion.Header>
              <Accordion.Body className="">
                No, Nudity is not allowed.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </div>
  );
};

export default LandingPage;
