import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import cardOne from "../../assets/img/dummy.jpg";
import {
  Card,
  CardBody,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardText,
  Media,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteListDataById } from "Api/Api";
import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { getListById } from "components/redux/actions/listActions";
import { FaSearch } from "react-icons/fa";
import { showSuccessAlert } from "components/Alerts/Alerts";
const CustomList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [filtereList, setFiltereList] = useState();
  const getList = useSelector((state) => state?.getListByIdReducer?.list);
  const Values = {
    userId: userId.id,
  };
  useEffect(() => {
    dispatch(getListById(Values));
  }, [dispatch]);
  useEffect(() => {
    setFiltereList(getList);
  }, [getList]);

  const deleteProfile = (id) => {
    const values = {
      listDataId: id,
    };
    deleteListDataById(values).then((data) => {
      if (data?.data?.message === "Data deleted") {
        showSuccessAlert("Removed from list");

        history.push("/admin/home");
      }
    });
  };
  const filteringBySearch = (e) => {
    setFiltereList(
      getList.filter((item) =>
        item.otherData?.firstName.includes(e.target.value)
      )
    );
  };

  return (
    <div className="content ">
      <Row>
        <Col xl={5}></Col>
        <Col xl={5}>
          <div className="home-input-addon" style={{ marginTop: "-50px" }}>
            <InputGroup style={{ borderRadius: "20px" }}>
              <InputGroupAddon
                addonType="prepend"
                className="home-search"
                style={{
                  background: "black",
                  borderTopLeftRadius: "20px",
                  borderBottomLeftRadius: "20px",
                }}
              >
                <InputGroupText
                  style={{
                    borderColor: "white",
                    borderRadius: "20px 0 0 20px",
                  }}
                >
                  <FaSearch
                    className="home-search"
                    style={{ color: "white" }}
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                style={{
                  background: "black",
                  borderColor: "white",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  color: "white",
                  zIndex: "8000",
                }}
                placeholder="Search"
                onChange={(e) => filteringBySearch(e)}
              />
            </InputGroup>
          </div>
        </Col>
      </Row>
      <div className="mr-lg-4">
        <Row className="mt-3 justify-content-center">
          <Col xl={8}>
            <h1
              className="text-white"
              style={{ fontStyle: "Roboto", fontSize: "50px" }}
            >
              Custom List
            </h1>

            {filtereList?.length ? (
              filtereList?.map((data) => (
                <Card
                  style={{ backgroundColor: "#161616", borderRadius: "10px" }}
                >
                  <Row>
                    <img
                      alt="Card cap"
                      src={
                        data?.backgroundPic
                          ? data?.backgroundPic
                          : "https://picsum.photos/318/180"
                      }
                      width="20%"
                      className="ml-4 mt-3 mb-3"
                      style={{ borderRadius: "20px" }}
                    />
                    <CardBody>
                      <CardText
                        className="ml-1"
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          color: "white",
                        }}
                      >
                        {data?.otherData?.about
                          ? data?.otherData?.about
                          : "Eroxr Content Creator"}
                        <span>
                          <AiFillEye
                            className="ml-lg-5 ml-md-5"
                            style={{
                              color: "white",
                              fontSize: "30px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              history.push(
                                `/admin/profile/${data?.otherData?._id}`
                              )
                            }
                          />
                          <AiFillDelete
                            className="ml-2"
                            onClick={() => deleteProfile(data?._id)}
                            style={{
                              color: "white",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      </CardText>
                      <CardText
                        className="ml-1"
                        href="#"
                        style={{ color: "white", fontWeight: "500" }}
                      >
                        You added this user to your list!
                      </CardText>
                      <Row>
                        <Col>
                          <Media className="mt-4 ml-3 mb-4">
                            <Media left>
                              <img
                                object
                                src={
                                  data?.otherData?.profilePic
                                    ? data?.otherData?.profilePic
                                    : cardOne
                                }
                                style={{ height: "50px", width: "50px" }}
                                alt="jannan"
                                className="rounded-circle"
                              />
                            </Media>
                            <Media body className="ml-2 mt-1">
                              <h4
                                className="text-white mb-0"
                                style={{ fontWeight: "600" }}
                              >
                                {data?.otherData?.firstName}
                              </h4>
                              <p className="chat-designation ml-0 mb-0">
                                http://
                                {data?.otherData.website
                                  ? data?.otherData.website
                                  : "@example.com"}
                              </p>
                              {
                                // <span className='mr-0' style={{color:"white"}}><HiLocationMarker/><span style={{fontSize:"10px"}}>England</span></span>
                              }
                            </Media>
                          </Media>
                        </Col>
                      </Row>
                    </CardBody>
                  </Row>
                </Card>
              ))
            ) : (
              <h2 className="text-center">No One Added!</h2>
            )}
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </div>
  );
};

export default CustomList;
