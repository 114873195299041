import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPosts as posts } from "components/redux/actions/postActions";
import "./Home.css";
import { AiOutlinePlusCircle } from "react-icons/ai";
import profilePic from "../../assets/img/dummy.jpg";
import "@lottiefiles/lottie-player";
import { Button, Row, Col } from "reactstrap";
import ChatPortion from "components/ChatPortion/ChatPortion";
import { getUserById } from "components/redux/actions/userActions";
import { getAllUsers } from "components/redux/actions/userActions";
import { useHistory } from "react-router-dom";
import { getAllAcceptedUsers } from "components/redux/actions/requestActions";
import { getRequestBySenderId } from "components/redux/actions/requestActions";
import Swal from "sweetalert2";
import Poll from "components/Poll/Poll";
import HomeModals from "components/Modals/HomeModals";
import { updateCallStatus } from "Api/Api";
import PusherConfig from "../../Api/Pusher";
import { showCallAlerts } from "components/Alerts/Alerts";
import CustomCard from "./CustomCard";
import { postLoadingAnimation } from "components/LottieAnimation/LottieAnimations";

const Home = () => {
  const history = useHistory();
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [showModal, setShowModal] = useState(false);
  const [filterePosts, setFilterePosts] = useState();
  const [page, setPage] = useState(1);
  const [streamPics, setStreamPics] = useState([]);
  const dispatch = useDispatch();
  const getPost = useSelector((state) => state?.getPosts);
  const userIdforPusher = userId?.id;
  const getSubscribedUser = useSelector(
    (state) => state?.getAllAcceptedRequestReducer?.accpetedRequests
  );
  const [hoveredImage, setHoveredImage] = useState(false);
  const getUser = useSelector((state) => state?.getUserById);
  const userData = getUser?.userData;
  const getAllUser = useSelector((state) => state?.getAllUsers);
  const AllUser = getAllUser?.allUsers;

  const values = {
    userId: userId.id,
  };

  const pageValue = {
    page: page,
  };

  useEffect(() => {
    dispatch(getUserById(values));
    dispatch(posts(pageValue));
    dispatch(getAllUsers());
    dispatch(getRequestBySenderId(values));
    dispatch(getAllAcceptedUsers(values));
  }, [page]);

  useEffect(() => {
    setFilterePosts(getPost?.posts);
  }, [getPost]);

  useEffect(() => {
    setStreamPics(
      AllUser?.filter(
        (data) => data?.liveStreamStatus === "live" && data?._id !== userId.id
      )
    );
  }, []);

  useEffect(() => {
    const channel = PusherConfig.subscribe(userIdforPusher);
    channel.bind("client-alert", async (data) => {
      if (data.message === "audio alert is coming") {
        const result = await showCallAlerts(
          data?.name,
          "is calling for audio call",
          "End-to-end encrypted Call"
        );
        if (result.isConfirmed) {
          changeCallStatus("answered", data?.senderId);
          history.push(`/admin/chatCall/${data?.senderId}`);
        } else if (result.isDismissed) {
          changeCallStatus("rejected", data?.senderId);
          Swal.close();
        }
      }
      if (data.message === "video alert is coming") {
        const result = await showCallAlerts(
          data?.name,
          "is calling for video call",
          "End-to-end encrypted Call"
        );
        if (result.isConfirmed) {
          changeCallStatus("answered", data?.senderId);
          history.push(`/admin/chatCall/${data?.senderId}`);
        } else if (result.isDismissed) {
          changeCallStatus("rejected", data?.senderId);
          Swal.close();
        }
      }
    });

    return () => {
      channel.unbind("client-alert");
      PusherConfig.unsubscribe(userIdforPusher);
      PusherConfig.disconnect();
    };
  }, [userIdforPusher]);

  const changeCallStatus = (status, senderId) => {
    const callValues = {
      recieverId: userId.id,
      senderId: senderId,
      status: status,
    };
    updateCallStatus(callValues);
  };

  const handleRightClick = (event) => {
    event.preventDefault();
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="content home-div" onContextMenu={handleRightClick}>
      <HomeModals isOpen={showModal} toggle={closeModal} />

      <Row className="justify-conten-center ">
        <Col xl={8} className="ml-lg-5">
          <Row>
            <Col>
              <h2 className="home-title">
                {userData?.firstName} {userData?.lastName}{" "}
                <span style={{ fontSize: "33px", cursor: "pointer" }}>
                  <AiOutlinePlusCircle onClick={() => setShowModal(true)} />
                </span>
              </h2>
            </Col>
          </Row>

          <div style={{ position: "relative" }}>
            <span
              style={{ color: "white", fontWeight: "600", fontSize: "18px" }}
            >
              Live{" "}
            </span>
            <span style={{ position: "absolute", top: "0.1em" }}>
              <span
                style={{
                  display: "inline-block",
                  width: "0.5em",
                  marginBottom: "0.3em",
                  height: "0.5em",
                  borderRadius: "50%",
                  backgroundColor: "red",
                }}
              ></span>
            </span>
          </div>

          <div className="container mt-2 mb-3">
            <div className="row">
              <div className="col">
                <ul className={hoveredImage ? "live-img " : "image-list"}>
                  {streamPics ? (
                    streamPics?.map((data, index) =>
                      getSubscribedUser?.map(
                        (datas) =>
                          datas?._id === data?._id && (
                            <li>
                              {hoveredImage === false && (
                                <img
                                  src={
                                    data?.profilePic
                                      ? data?.profilePic
                                      : profilePic
                                  }
                                  alt=""
                                  onMouseOver={() => setHoveredImage(true)}
                                  onMouseOut={() => setHoveredImage(false)}
                                  onClick={() =>
                                    history.push(
                                      `/admin/liveStreaming/${data?._id}`
                                    )
                                  }
                                />
                              )}
                              {hoveredImage === true && (
                                <video
                                  controls
                                  src={data?.backgroundImage}
                                  style={{
                                    borderRadius: "80px",
                                    width: "70px",
                                    height: "70px",
                                  }}
                                  autoPlay
                                  onMouseOver={() => setHoveredImage(true)}
                                  onMouseOut={() => setHoveredImage(false)}
                                  onClick={() =>
                                    history.push(
                                      `/admin/liveStreaming/${data?._id}`
                                    )
                                  }
                                />
                              )}
                            </li>
                          )
                      )
                    )
                  ) : (
                    <span
                      style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      Go Live!
                    </span>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <span style={{ color: "white", fontWeight: "600", fontSize: "18px" }}>
            Lets Discover{" "}
          </span>

          {filterePosts
            ? filterePosts?.map((data, index) => (
                <>
                  {data.key === "post" &&
                    data.subscriberCheck === true &&
                    data?.userId === userId?.id && <CustomCard data={data} />}
                  {data.key === "post" &&
                    data.subscriberCheck === true &&
                    getSubscribedUser?.some(
                      (datas) => datas?._id === data?.userId
                    ) && <CustomCard data={data} />}
                  {data.key === "post" && data.subscriberCheck === false && (
                    <CustomCard data={data} />
                  )}
                  {data.key === "poll" && <Poll data={data} />}
                </>
              ))
            : postLoadingAnimation()}
          <h1 className="text-center">
            <Button className="auth-anim-btn" onClick={() => setPage(page + 1)}>
              Load More
            </Button>
          </h1>
        </Col>

        <ChatPortion />
      </Row>
    </div>
  );
};

export default Home;
