import ChatPortion from "components/ChatPortion/ChatPortion";
import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import privacyOne from "../../assets/img/security.png";
import { privacyList } from "mock_data/listing";
const Privacy = () => {
  
  return (
    <div className="content">
      <span
        className=""
        style={{ color: "white", fontSize: "10px", marginLeft: "50px" }}
      >
        <Link
          to="/admin/home"
          style={{
            color: "white",
            fontSize: "40px",
            marginTop: "-4%",
            position: "absolute",
          }}
        >
          <IoMdArrowRoundBack />
        </Link>
      </span>
      <Row>
        <Col xl={10} style={{ marginLeft: "-50px" }}>
          <Row className="">
            <Col className="text-center">
              <img src={privacyOne} style={{ color: "white" }} alt="" />
              <h2
                className="text-white mb-0 mt-4"
                style={{ fontWeight: "600" }}
              >
                Privacy Policy
              </h2>
              <p className="" style={{ color: "grey", fontSize: "13px" }}>
                It's a good idea to use a strong password that you're not using{" "}
                <br /> elsewhere
              </p>
            </Col>
          </Row>
          <FormGroup check className="mt-4">
            <Input
              type="radio"
              className="mt-1"
              style={{ marginLeft: "12%" }}
            />{" "}
            <Label
              style={{
                color: "white",
                fontWeight: "600",
                fontSize: "20px",
                marginLeft: "15%",
              }}
            >
              Privacy
            </Label>
            <Row className="justify-content-center">
              <Col xl={8}>
                <div>
                  <ul>
                    {privacyList.map((data) => (
                      <li style={{ alignItems: "center" }}>
                        <p
                          className=""
                          style={{ fontSize: "12px", color: "#AFAEA2" }}
                        >
                          {data.title}
                        </p>
                        <p style={{ fontSize: "12px", color: "#AFAEA2" }}>
                          {data.text}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col xl={2}>
          <ChatPortion />
        </Col>
      </Row>
    </div>
  );
};

export default Privacy;
