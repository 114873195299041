import React, {useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";
import "./Modals.css";
import { updateUser } from "Api/Api";
import { getUserById } from "components/redux/actions/userActions";
import { useDispatch } from "react-redux";
import { showSuccessAlert } from "components/Alerts/Alerts";
import { showErrorAlert } from "components/Alerts/Alerts";

const WishlistModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [animationCheck, setAnimationCheck] = useState(false);

  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [profileWishlist, setProfileWishlist] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const Values = {
    userId: userId.id,
  };

  function toggleModal() {
    setShowModal(!showModal);
  }
  const updation = async () => {
    setAnimationCheck(true);
    const values = {
      userId: userId.id,
      profileWishlist: profileWishlist,
    };
    await updateUser(values).then((res) => {
      if (res.data.message === "user updated") {
        showSuccessAlert("Wishlist updated");

        setTimeout(() => {
          history.push("/admin/profile/:id");
        }, 2000);
      } else {
        showErrorAlert("Server Error");

        setAnimationCheck(false);
      }
      dispatch(getUserById(Values));
    });
  };

  return (
    <div className="content">
      <div>
        <p
          style={{ fontSize: "33px", cursor: "pointer", color: "white" }}
          className="text-right"
        >
          <AiOutlinePlusCircle onClick={() => setShowModal(true)} />
        </p>

        <Modal
          isOpen={showModal}
          toggle={toggleModal}
          style={{ marginTop: "100px" }}
        >
          <>
            <div className="modal-header">
              <h4
                className="text-white text-center"
                style={{ fontWeight: "600" }}
              >
                Ad Wish To Your List
              </h4>
            </div>
            <hr
              style={{ backgroundColor: "#555555", marginTop: "-10px" }}
              className="mr-3 ml-3"
            />
            <div
              className="modal-body home-modal"
              style={{ borderRadius: "40px" }}
            >
              <FormGroup className="mt-4 mb-4" row>
                <Label
                  for="exampleEmail"
                  sm={2}
                  style={{
                    color: "white",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  Wish
                </Label>
                <Col sm={10}>
                  <Input
                    id="exampleEmail"
                    name="email"
                    type="text"
                    className="reset-input "
                    placeholder="Enter your link"
                    onChange={(e) => setProfileWishlist(e.target.value)}
                  />
                </Col>
              </FormGroup>
            </div>
            <hr
              style={{ backgroundColor: "#555555", marginTop: "-10px" }}
              className="mr-3 ml-3"
            />
            <Row className="justify-content-end">
              <Col xl={12} className="text-right">
                {animationCheck ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <lottie-player
                      src="https://assets6.lottiefiles.com/packages/lf20_vpxae5vy.json"
                      background="transparent"
                      speed="1"
                      style={{ width: "100px", height: "100px" }}
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                ) : (
                  <Button
                    type="button"
                    className="  reset-button mr-2 mb-2"
                    onClick={updation}
                  >
                    Add
                  </Button>
                )}
              </Col>
            </Row>
          </>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};

export default WishlistModal;
