import React from 'react';
import profilSeven from "../../assets/img/dummy.jpg";
import './Slider.css'
import { Col, Row } from 'reactstrap';
import { useEffect } from 'react';
import { getAllUsers } from 'components/redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AiFillFacebook, AiFillInstagram } from 'react-icons/ai';


const Slider = () => {

  const dispatch=useDispatch()
  const history=useHistory()
  useEffect(() => {
     
      dispatch(getAllUsers())
    
        
    }, [dispatch])
    const getAllUser= useSelector(state => state?.getAllUsers);
    const AllUser=getAllUser?.allUsers
  
  return (
  <div className="banner">
  
  {AllUser?.map((data,index)=>(
   data?.creator===true&&
 
 <Col xl={4}>
      <div className="profile-card ml-sm-4 mr-sm-4">
        <div className="profile-image">
          <img src={data?.profilePic || profilSeven} alt="Profile" className="profile-image" />
        </div>
        <div className="profile-info">
          <h2 className="name">
            {data?.firstName} {data?.lastName}
          </h2>
          <div className="social-links mt-1">
            <a href={data?.instagramLink} target="_blank" rel="noopener noreferrer">
              <AiFillInstagram
              className="ml-3"
              style={{ color: "black", fontSize: "20px" }}
            />
            </a>
            <a href={data?.facebookLink} target="_blank" rel="noopener noreferrer">
             <AiFillFacebook
              className="ml-2"
              style={{ color: "black", fontSize: "20px" }}
            />
            </a>
          </div>
          <div className="subscribers mt-1">
            <p style={{color:"black"}}>Subscribers: {data?.subscribers || 0}</p>
          </div>
          <button
            className="btn auth-anim-btn"
            style={{ paddingLeft: "50px", paddingRight: "50px" }}
            onClick={() => history.push('/login')}
          >
            View Profile
          </button>
        </div>
      </div>
    </Col>
    

     ))}
    
  
  </div>

  );
};

export default Slider;
