import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import "./Navbar.css";
import navOne from "../../assets/img/dummy.jpg";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  UncontrolledDropdown,
  Navbar,
  Nav,
  Container,
  NavbarToggler,
} from "reactstrap";
import { getUserById } from "components/redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";

function AdminNavbar(props) {
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const getUser = useSelector((state) => state?.getUserById);
  const userData = getUser?.userData;
 
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };

  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };

  const dispatch = useDispatch();
  const values = {
    userId: userId.id,
  };
  useEffect(() => {
    dispatch(getUserById(values));
  }, [dispatch]);

  return (
    <>
      {props.brandText !== "Chat" && (
        <Navbar className={classNames("navbar-absolute", color)} expand="lg">
          <Container fluid>
            <div className="navbar-wrapper">
              {props.brandText !== "Live" && props.brandText !== "chat" && (
                <div
                  className={classNames("navbar-toggle d-inline", {
                    toggled: props.sidebarOpened,
                  })}
                >
                  <NavbarToggler onClick={props.toggleSidebar}>
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </NavbarToggler>
                </div>
              )}
            </div>

            {props.brandText !== "Profile" &&
              props.brandText !== "Dasboard" &&
              props.brandText !== "Home" &&
              props.brandText !== "Authentication" &&
              props.brandText !== "PasswordReset" &&
              props.brandText !== "AccountManagement" &&
              props.brandText !== "Be a G.O.A.T Member" &&
              props.brandText !== "createPost" &&
              props.brandText !== "createAd" &&
              props.brandText !== "Dashboard" &&
              props.brandText !== "Ads" &&
              props.brandText !== "AdDescription" &&
              props.brandText !== "Live" &&
              props.brandText !== "editProfile" &&
              props.brandText !== "Help & Support" &&
              props.brandText !== "Custom List" &&
              props.brandText !== "Wishlist" &&
              props.brandText !== "Brand" && (
                <NavbarToggler onClick={toggleCollapse}>
                  <span className="navbar-toggler-bar navbar-kebab" />
                  <span className="navbar-toggler-bar navbar-kebab" />
                  <span className="navbar-toggler-bar navbar-kebab" />
                </NavbarToggler>
              )}

            <Collapse navbar isOpen={collapseOpen}>
              <Nav className="ml-auto mt-3" navbar>
                {props.brandText !== "Profile" &&
                  props.brandText !== "Dashboard" &&
                  props.brandText !== "Home" &&
                  props.brandText !== "Authentication" &&
                  props.brandText !== "PasswordReset" &&
                  props.brandText !== "AccountManagement" &&
                  props.brandText !== "Be a G.O.A.T Member" &&
                  props.brandText !== "Help & Support" &&
                  props.brandText !== "Live" &&
                  props.brandText !== "createPost" &&
                  props.brandText !== "createAd" &&
                  props.brandText !== "createPoll" &&
                  props.brandText !== "AdDescription" &&
                  props.brandText !== "editProfile" &&
                  props.brandText !== "Wishlist" &&
                  props.brandText !== "Brand" && (
                    <>
                      <UncontrolledDropdown nav>
                        <DropdownToggle
                          caret
                          color="default"
                          nav
                          onClick={(e) => e.preventDefault()}
                        >
                          <div className="photo">
                            <img
                              alt="..."
                              src={
                                userData?.profilePic
                                  ? userData?.profilePic
                                  : navOne
                              }
                            />
                          </div>
                          <span className="text-white navbar-name ml-2">
                            {userData?.firstName} {userData?.lastName}
                          </span>

                          <p className="d-lg-none">Log out</p>
                        </DropdownToggle>
                      </UncontrolledDropdown>

                      <li className="separator d-lg-none" />
                    </>
                  )}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      )}
    </>
  );
}

export default AdminNavbar;
