import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { createPayment } from "Api/Api";
import { CARD_OPTIONS } from "components/DynamicFuntions";
import { ActionAnimation } from "components/LottieAnimation/LottieAnimations";
import { showErrorAlert } from "components/Alerts/Alerts";
import { showSuccessAlert } from "components/Alerts/Alerts";

const EroxrFeeModal = ({ isOpen, toggle, userData }) => {
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [animationCheck, setAnimationCheck] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [state, setState] = useState();
  const [postalCode, setPostalCode] = useState();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  let values = {};
  const handlePayment = async (e) => {
    e.preventDefault();
    setAnimationCheck(true);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (!error) {
        try {
          const { id } = paymentMethod;
          if (userData) {
            values = {
              userId: userData?._id,
              name: name,
              email: email,
              postalCode: postalCode,
              state: state,
              paymentId: id,
            };
          } else {
            values = {
              userId: userId.id,
              name: name,
              email: email,
              postalCode: postalCode,
              state: state,
              paymentId: id,
            };
          }

          createPayment(values).then((res) => {
            console.log(values);
            if (res.data.message === "payment Successfull") {
              showSuccessAlert("payment Successfull");

              if (userData) {
                setTimeout(() => {
                  history.push("/login");
                }, 2000);
              } else {
                setTimeout(() => {
                  history.push("/admin/home");
                }, 2000);
              }
            } else {
              setAnimationCheck(false);
              showErrorAlert("Server Error");
            }
          });
        } catch (error) {
          setAnimationCheck(false);
          showErrorAlert("Server Error");
        }
      }
    } catch (ex) {
      setAnimationCheck(false);
      showErrorAlert("Server Error");
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: "-60px" }}>
      <h3 className="text-center text-white mb-0 mt-2">
        Buy Our premium features!
      </h3>

      <ModalBody>
        <Form onSubmit={handlePayment}>
          <FormGroup check className="">
            <Input type="radio" className="mt-2" />{" "}
            <Label
              style={{ color: "white", fontWeight: "600", fontSize: "18px" }}
              className="mt-1"
            >
              Information
            </Label>
          </FormGroup>
          <Row>
            <Col xl={12}>
              <FormGroup className="mt-3">
                <Label
                  for="exampleEmail"
                  style={{ color: "white", fontWeight: "600" }}
                >
                  First and last name
                </Label>
                <Input
                  required
                  placeholder="Name..."
                  className="post-input"
                  onChange={(e) => setName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  for="exampleEmail"
                  style={{ color: "white", fontWeight: "600" }}
                >
                  Email adress
                </Label>
                <Input
                  required
                  type="email"
                  placeholder="Email adress"
                  className="post-input"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>

              <Row>
                <Col>
                  <FormGroup>
                    <Label
                      for="exampleEmail"
                      style={{ color: "white", fontWeight: "600" }}
                    >
                      State
                    </Label>
                    <Input
                      required
                      type="text"
                      placeholder="state"
                      className="post-input"
                      onChange={(e) => setState(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label
                      for="exampleEmail"
                      style={{ color: "white", fontWeight: "600" }}
                    >
                      Zip/Postal Code
                    </Label>
                    <Input
                      required
                      type="number"
                      placeholder="zip/postal code"
                      className="post-input"
                      onChange={(e) => setPostalCode(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <CardElement options={CARD_OPTIONS} className="mt-4" />

              {animationCheck ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {ActionAnimation()}
                </div>
              ) : (
                <>
                  <h1 className="text-center mt-3 mb-0">
                    <Button className="pay-btn reset-button mt-2" type="submit">
                      Sumbit
                    </Button>
                  </h1>
                </>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EroxrFeeModal;
