import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  Button,
} from "reactstrap";
import Logo from '../../assets/img/logo5.png';

import { useHistory } from "react-router-dom";
import './Navbar.css';

function AdminNavbar(props) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const history = useHistory();

  const handleToggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  useEffect(() => {
    const updateColor = () => {
      if (window.innerWidth < 993 && collapseOpen) {
        setCollapseOpen(false);
      }
    };

    window.addEventListener("resize", updateColor);

    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  }, [collapseOpen]);

  return (
    <Navbar
      className={classNames("", {
        "navbar-transparent": !collapseOpen,
        "bg-transparent": collapseOpen,
      })}
      expand="sm"
      
    >
     
          <NavbarBrand
            onClick={(e) => e.preventDefault()}
            style={{ cursor: "default",position:"static" }}
          >
            <img src={Logo} alt="" className="" style={{width:"150px"}}/>
          </NavbarBrand>
        
        {props.brandText === "Brand" && (
          <>
            <NavbarToggler onClick={handleToggleCollapse}>
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </NavbarToggler>
            <Collapse navbar isOpen={collapseOpen}>
              <Nav className="ml-auto" navbar>
                <h1 className='landing-heading auth-eroxr-text'>EROXR</h1>
                
                  
              </Nav>
              <Nav className="ml-auto" navbar>
                
                  <Button
                    className="btn landing-button"
                    onClick={() => history.push('/login')}
                  >
                   <span className='gradient-text'>Login</span> 
                  </Button>
                  
              </Nav>
              <Nav className="" navbar>
                
                  <Button
                    className="btn landing-button"
                    onClick={() => history.push('/register')}
                  >
                 <span className='gradient-text'>Signup</span> 
                  </Button>
                  
              </Nav>
            </Collapse>
          </>
        )}
     
    </Navbar>
  );
}

export default AdminNavbar;
