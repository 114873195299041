import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillMail } from "react-icons/ai";
import "./Ads.css";
// import cardOne from './j27.png'
import {
  Button,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardText,
  Dropdown,
} from "reactstrap";
import { data } from "jquery";
import cardPic from "../../assets/img/j30.png";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAds as ads } from "components/redux/actions/adsActions";
import { FaSearch } from "react-icons/fa";
import { AdCounterIncrement } from "Api/Api";
import { ToastContainer } from "react-toastify";
import EroxrFeeModal from "components/Modals/EroxrFeeModal";
import { Elements } from "@stripe/react-stripe-js";
import { countries } from "mock_data/listing";
import { showSuccessAlert } from "components/Alerts/Alerts";
import { cityList } from "mock_data/listing";
import { postLoadingAnimation } from "components/LottieAnimation/LottieAnimations";
import { FeeAnimation } from "components/LottieAnimation/LottieAnimations";
import stripePromise from "Api/Stripe";
import { IoMdArrowDropdown } from "react-icons/io";

const Ads = () => {
  const history = useHistory();
  const [CountrydropdownOpen, setCountryDropdownOpen] = useState(false);
  const [CitydropdownOpen, setCityDropdownOpen] = useState(false);
  const [GenderdropdownOpen, setGenderDropdownOpen] = useState(false);
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [filtereAds, setFiltereAds] = useState();
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [showModal, setShowModal] = useState(false);
  const Countrytoggle = () => setCountryDropdownOpen(!CountrydropdownOpen);
  const Citytoggle = () => setCityDropdownOpen(!CitydropdownOpen);
  const Gendertoggle = () => setGenderDropdownOpen(!GenderdropdownOpen);
  const dispatch = useDispatch();
  const getAds = useSelector((state) => state?.getAds);
  const getUser = useSelector((state) => state?.getUserById);
  const userData = getUser?.userData;

  useEffect(() => {
    dispatch(ads());
  }, []);
  useEffect(() => {
    setFiltereAds(getAds?.ads);
  }, [getAds]);
  useEffect(() => {
    filteredAds();
  }, [country, city, gender]);

  const filteredAds = () => {
    if (country && city === "" && gender === "") {
      console.log(data, "coming in it for ad");
      setFiltereAds(getAds?.ads?.filter((item) => item?.country === country));
    }
    if (country && city && gender === "") {
      console.log(data, "coming in it for CITY ad");

      setFiltereAds(
        getAds?.ads?.filter(
          (item) => item?.country === country && item.city === city
        )
      );
    }
    if (country && city && gender) {
      setFiltereAds(
        getAds?.ads?.filter(
          (item) =>
            item?.country === country &&
            item.city === city &&
            item.gender === gender
        )
      );
    }
    if (country === "" && city === "" && gender !== "") {
      setFiltereAds(getAds?.ads?.filter((item) => item?.gender === gender));
    }
    if (country === "" && city && gender !== "") {
      setFiltereAds(
        getAds?.ads?.filter(
          (item) => item?.gender === gender && item.city === city
        )
      );
    }

    if (country === "" && city && gender === "") {
      setFiltereAds(getAds?.ads?.filter((item) => item?.city === city));
    }
    if (country && city === "" && gender !== "") {
      setFiltereAds(
        getAds?.ads?.filter(
          (item) => item?.country === country && item?.gender === gender
        )
      );
    }
  };

  const filteringBySearch = (e) => {
    setFiltereAds(
      getAds?.ads?.filter(
        (item) =>
          item.userData?.firstName.includes(e.target.value) ||
          item.userData?.lastName.includes(e.target.value)
      )
    );
  };
  const incrementClick = (id) => {
    const values = {
      id: id,
    };
    AdCounterIncrement(values);
  };
  const handleAlert = () => {
    showSuccessAlert("you are not subscribed to this user");
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="content">
      <Elements stripe={stripePromise} className="">
        <EroxrFeeModal isOpen={showModal} toggle={closeModal} />
      </Elements>
      {userData?.eroxrFee === true && (
        <>
          <Row>
            <Col xl={5} md={5} sm={5} className=""></Col>
            <Col xl={5} md={5} sm={5} className="">
              <div className="home-input-addon" style={{ marginTop: "-45px" }}>
                <InputGroup style={{ borderRadius: "20px" }}>
                  <InputGroupAddon
                    addonType="prepend"
                    className="home-search"
                    style={{
                      background: "black",
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }}
                  >
                    <InputGroupText
                      style={{
                        borderColor: "white",
                        borderRadius: "20px 0 0 20px",
                      }}
                    >
                      <FaSearch
                        className="home-search"
                        style={{ color: "white" }}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    style={{
                      background: "black",
                      borderColor: "white",
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                      color: "white",
                      zIndex: "8000",
                    }}
                    placeholder="Search"
                    onChange={(e) => filteringBySearch(e)}
                  />
                </InputGroup>
              </div>
            </Col>
          </Row>
          <h1
            className="text-white mt-1 ml-lg-4"
            style={{ fontStyle: "Roboto" }}
          >
            Contact ads
          </h1>
          <Row className="">
            <Col xl={2}>
              <Dropdown
                isOpen={GenderdropdownOpen}
                toggle={Gendertoggle}
                onClick={() => {
                  setFiltereAds(getAds?.ads);
                }}
                className="ml-lg-5"
              >
                <DropdownToggle className="dropDown toggle-down">
                  All
                </DropdownToggle>
              </Dropdown>
            </Col>

            <Col xl={2}>
              <Dropdown
                isOpen={CountrydropdownOpen}
                toggle={Countrytoggle}
                className="ml-lg-3"
              >
                <DropdownToggle
                  className="dropDown toggle-down-city"
                  style={{ padding: "100px" }}
                >
                  {country ? country : "Country"}
                  <IoMdArrowDropdown
                    className="mr-1"
                    style={{ fontSize: "20px" }}
                  />
                </DropdownToggle>
                <DropdownMenu className="country-menu">
                  {countries?.map((country) => (
                    <DropdownItem
                      key={country.code}
                      onClick={() => {
                        setCountry(country.name);
                        filteredAds();
                      }}
                    >
                      {country.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>

            <Col xl={2}>
              <Dropdown
                isOpen={CitydropdownOpen}
                toggle={Citytoggle}
                className="ml-lg-5"
              >
                <DropdownToggle className="dropDown toggle-down-city">
                  {city ? city : "City"}
                  <IoMdArrowDropdown
                    className="mr-1"
                    style={{ fontSize: "20px" }}
                  />
                </DropdownToggle>
                <DropdownMenu className="country-menu">
                  {cityList?.map((data, index) => (
                    <DropdownItem
                      onClick={() => {
                        setCity(data.name);
                        filteredAds();
                      }}
                      key={index}
                    >
                      {data.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>

            <Col xl={3}>
              <Dropdown
                isOpen={GenderdropdownOpen}
                toggle={Gendertoggle}
                onClick={() => {
                  setGender("Male");
                  filteredAds();
                }}
                className="ml-lg-5"
              >
                <DropdownToggle className="dropDown toggle-down">
                  Looking for Male
                </DropdownToggle>
              </Dropdown>
            </Col>

            <Col xl={3}>
              <Dropdown
                isOpen={GenderdropdownOpen}
                toggle={Gendertoggle}
                onClick={() => {
                  setGender("Female");
                  filteredAds();
                }}
                className=""
              >
                <DropdownToggle className="dropDown toggle-down">
                  Looking for Female
                </DropdownToggle>
              </Dropdown>
            </Col>
          </Row>
          <Row className="">
            {getAds
              ? filtereAds?.map((data) => (
                  <Col xl={4}>
                    <Card
                      className="mt-2 "
                      style={{
                        backgroundColor: "#161616",
                        borderRadius: "20px",

                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push(`/admin/adDescription/${data._id}`);
                        incrementClick(data._id);
                      }}
                    >
                      <img
                        alt="Card cap"
                        src={data?.adPic ? data?.adPic : cardPic}
                        className="ml-2 mr-2 mt-2 mb-1 "
                        style={{
                          borderRadius: "20px",
                          maxWidth: "100%",
                          maxHeight: "250px",
                          objectFit: "cover",
                        }}
                      />
                      <div style={{ display: "flex" }} className="ml-4">
                        <AiFillEye className="contact-profile" />{" "}
                        <span
                          className="ml-2"
                          style={{
                            marginTop: "-35px",
                            color: "purple",
                            fontSize: "15px",
                            fontWeight: "700",
                          }}
                        >
                          {data?.counter}
                        </span>
                      </div>

                      <CardBody>
                        <CardText
                          className="ml-1 mb-0 "
                          style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "white",
                          }}
                        >
                          {data?.userData?.profileName
                            ? data?.userData?.profileName
                            : data?.userData?.firstName}
                        </CardText>
                        <CardText
                          className="ml-1 mt-0"
                          href="#"
                          style={{ color: "grey", fontWeight: "500" }}
                        >
                          {data?.description}
                        </CardText>
                        <Row className="mt-3">
                          <Col>
                            <CardText
                              className="ml-2"
                              href="#"
                              style={{ color: "#F34191", fontWeight: "500" }}
                              onClick={handleAlert}
                            >
                              contact
                            </CardText>
                          </Col>
                          <Col className="text-right">
                            <AiFillMail
                              style={{ fontSize: "20px", color: "white" }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              : postLoadingAnimation()}
          </Row>
        </>
      )}
      {userData?.eroxrFee === false && (
        <Col xl={10} className="">
          <Row className="justify-content-center mt-5">{FeeAnimation()}</Row>
          <h1 className="text-center">
            <Button
              type="submit"
              onClick={() => setShowModal(true)}
              className="reset-button mr-2"
              style={{ paddingLeft: "200px", paddingRight: "210px" }}
            >
              Buy our MemberShip!
            </Button>
          </h1>
        </Col>
      )}
      <ToastContainer />
    </div>
  );
};

export default Ads;
