import React, { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MembershipSection from "components/Membership/MembershipSection";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import WishlistModal from "components/Modals/WishlistModal";
import { getReduxUserById } from "components/redux/actions/userActions";

const Wishlist = () => {
  const { id } = useParams();
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const user = useSelector((state) =>
    getReduxUserById(state?.getAllUsers?.allUsers, id)
  );

  const userData = user;
  return (
    <div className="content" style={{ zoom: "0.95", marginTop: "-3%" }}>
      <Link
        to="/admin/profile/:id"
        style={{
          color: "white",
          fontSize: "40px",
          cursor: "pointer",
        }}
      >
        <IoMdArrowRoundBack style={{ zIndex: "2000" }} />
      </Link>

      <Row className="">
        <Col xl={8}>
          <h3
            className="text-white mb-0 mt-3 text-center"
            style={{ fontWeight: "600", marginLeft: "10%" }}
          >
            Wishlist
          </h3>
          <Row className="justify-content-center">
            {userData?.profileWishlist?.length ? (
              userData?.profileWishlist?.map((data, index) => (
                <Col xl={8} className="text-center">
                  <FormGroup row className="mt-3">
                    <Label
                      for="exampleEmail"
                      sm={2}
                      style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      {index + 1}
                    </Label>
                    <Col sm={10}>
                      <Input
                        id="exampleEmail"
                        value={data}
                        placeholder=""
                        type="text"
                        className="reset-input"
                      />
                    </Col>
                  </FormGroup>
                </Col>
              ))
            ) : (
              <h3 className="mt-3" style={{ marginLeft: "10%" }}>
                Wishlist is empty!
              </h3>
            )}
          </Row>
          {id === userId?.id && (
            <Row className="justify-content-center">
              <Col xl={8} className="">
                <WishlistModal />
              </Col>
            </Row>
          )}
        </Col>
        <Col xl={4}>
          <MembershipSection />
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default Wishlist;
