import React, { useEffect, useState } from "react";
import { Media } from "reactstrap";
import "./ChatSection.css";
import streamFour from "../../assets/img/dummy.jpg";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLastMessage } from "Api/Api";
import { BsDot } from "react-icons/bs";
import { updateReadStatus } from "Api/Api";
import { getContactById } from "components/redux/actions/contactActions";
import { handleMessage } from "components/DynamicFuntions";
import WidthCheck from "components/WidthCheck";

const ChatSection = ({ dataValue, msgValue }) => {
  const dispatch = useDispatch();
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const getAllAcceptedRequests = useSelector(
    (state) => state?.getContactById?.contactById
  );
  const [width, setWidth] = useState(window.innerWidth);
  const [lastMessages, setLastMessages] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPortion, setIsOpenPortion] = useState(false);
  const [readCheck, setReadCheck] = useState(false);
  const getUser = useSelector((state) => state?.getUserById);
  const userData = getUser?.userData;
  let readChats = getAllAcceptedRequests;
  let arrayforLastMessage = [];
  useEffect(() => {
    readChats?.map((datass) => {
      arrayforLastMessage.push({
        senderId: userId.id,
        recieverId: datass?._id,
      });
    });
  }, [userId]);

  useEffect(() => {
    getLastMessage(arrayforLastMessage).then((res) => {
      setLastMessages(res?.data?.data);
      
    });
  }, []);

  const toggleOpenPortion = (val1, val2) => {
    setIsOpenPortion(val2);
    setIsOpen(val1);
  };

  const sendDataToChat = (data) => {
    const value = {
      senderId: userId.id,
    };
    updateReadStatus(value).then((res) => {
      if (res?.data?.message === "updated") {
        setReadCheck(false);
      }
    });
    dataValue(data);
  };
  const sendMessageToChat = (data) => {
    msgValue(data);
  };
  const values = {
    userId: userId.id,
  };
  useEffect(() => {
    dispatch(getContactById(values));
  }, [dispatch]);

  return (
    <>
      <WidthCheck handleOpenValue={toggleOpenPortion} component="chat-sec"/>
      <div className={isOpen ? "open-chats" : "chat-chats"}>
        <div style={{ display: "flex" }} className="mt-3">
          <span
            className="mt-3 ml-3"
            style={{ color: "white", fontSize: "30px" }}
          >
            <Link to="/admin/home" style={{ color: "white", fontSize: "30px" }}>
              <IoMdArrowRoundBack />
            </Link>
          </span>
          <h2
            className="text-white text-center mt-4 ml-4"
            style={{ fontStyle: "Roboto", fontWeight: "600" }}
          >
            Chats
          </h2>
        </div>
        <div className="chat-main-chats">
          {readChats?.map((datass, index) => (
            <div key={index}>
              <Media
                className="mt-2 ml-3 chat-media"
                style={{ cursor: "pointer" }}
                onClick={() => sendDataToChat(datass)}
              >
                <Media left>
                  <img
                    src={datass?.profilePic ? datass?.profilePic : streamFour}
                    style={{ widht: "60px", height: "60px" }}
                    class="rounded-circle  mt-3 mb-4 "
                    alt=""
                  />
                  {userData?.onlineStatus === true && (
                    <span style={{ position: "absolute" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "0.7em",
                          marginLeft: "-1em",
                          height: "0.7em",
                          marginBottom: "-1em",
                          borderRadius: "50%",
                          backgroundColor: "green",
                        }}
                      ></span>
                    </span>
                  )}
                </Media>
                <Media body className="ml-2 mt-4">
                  <h3 className="text-white mb-0">{datass?.firstName} </h3>

                  {lastMessages?.map((msg) => (
                    <>
                      {msg?.senderId === datass?._id && (
                        <p
                          className="chat-designation"
                          onClick={() => sendMessageToChat(msg?.message)}
                          style={{
                            fontSize: "16px",
                            fontWeight: msg?.readStatus === false ? "700" : "",
                          }}
                        >
                          {handleMessage(msg?.message)}
                          {msg?.recieverId === userId?.id &&
                            msg?.readStatus === false && (
                              <span className="ml-3">
                                <BsDot
                                  style={{
                                    fontSize: "30px",
                                    color: "red",
                                    fontWeight: "700",
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </span>
                            )}
                        </p>
                      )}

                      {msg?.recieverId === datass?._id && (
                        <p
                          className="chat-designation"
                          onClick={() => sendMessageToChat(msg?.message)}
                          style={{
                            fontSize: "16px",
                            fontWeight: msg?.readStatus === false ? "700" : "",
                          }}
                        >
                          {handleMessage(msg?.message)}
                          {msg?.recieverId === userId?.id &&
                            msg?.readStatus === false && (
                              <span className="ml-3">
                                <BsDot
                                  style={{
                                    fontSize: "30px",
                                    color: "red",
                                    fontWeight: "700",
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </span>
                            )}
                        </p>
                      )}
                    </>
                  ))}
                </Media>
              </Media>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ChatSection;
