import React from "react";
import footerOne from "../../assets/img/j4.png";
import footerTwo from "../../assets/img/j5.png";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiOutlineTwitter,
} from "react-icons/ai";
// reactstrap components
import { Container, Nav, NavItem, NavLink } from "reactstrap";
import "./footer.css";

function Footer() {
  return (
    <footer
      className="footer"
      style={{ padding: "7px", backgroundColor: "#161616", zIndex: "100" }}
    >
      <Container fluid>
        <Nav className="w-100">
          <p style={{ color: "#CFCFCF", fontSize: "8px" }}>
            Download our mobile app
          </p>
          <NavItem>
            <NavLink href="https://www.creative-tim.com/?ref=bdr-user-archive-footer">
              <img src={footerOne} alt="apple" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://www.creative-tim.com/presentation?ref=bdr-user-archive-footer">
              <img src={footerTwo} alt="google" />
            </NavLink>
          </NavItem>
          <NavItem className="footer-items">
            <NavLink href="https://www.creative-tim.com/presentation?ref=bdr-user-archive-footer">
              <span
                className=""
                style={{
                  color: "#CFCFCF",
                  fontSize: "8px",
                  marginBottom: "-60px",
                  fontWeight: "600",
                }}
              >
                All rights reserved.Eroxr 2023. By visiting this page you agree
                to our <span style={{ color: "#229ED9" }}>Privacy policy </span>{" "}
                and{" "}
                <span style={{ color: "#229ED9" }}> Terms and Conditions</span>
              </span>
            </NavLink>
          </NavItem>
          <NavItem className="footer-items">
            <NavLink href="https://www.creative-tim.com/presentation?ref=bdr-user-archive-footer">
              <span className="ml-1">
                <AiFillFacebook style={{ fontSize: "18px" }} />
              </span>
              <span className="ml-1">
                <AiFillInstagram style={{ fontSize: "18px" }} />
              </span>
              <span className="ml-1">
                <AiFillLinkedin style={{ fontSize: "18px" }} />
              </span>
              <span className="ml-1">
                <AiOutlineTwitter style={{ fontSize: "18px" }} />
              </span>
            </NavLink>
          </NavItem>
        </Nav>
      </Container>
    </footer>
  );
}

export default Footer;
