import React from "react";
import "./Live.css";
import { Row, Col, Button } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import LiveSection from "./LiveSection/LiveSection";
import liveFive from "../../assets/img/j40.jpg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { BsCameraVideoFill } from "react-icons/bs";
import UserCheck from "components/UserCheck";

const Live = () => {
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [streamThumb, setStreamThumb] = useState([]);
  const history = useHistory();
  const getUser = useSelector((state) => state?.getUserById);
  const userData = getUser?.userData;
  const getAllUser = useSelector((state) => state?.getAllUsers);
  const AllUser = getAllUser?.allUsers;
  const getSubscribedUser = useSelector(
    (state) => state?.getAllAcceptedRequestReducer?.accpetedRequests
  );

  const checkBackgroundImage = (e) => {
    const check = e?.backgroundImage?.includes("video");
    if (check) {
      return (
        <video
          controls
          autoplay
          muted
          className=" streams-image-two mt-2"
          src={e?.backgroundImage}
          style={{ height: "210px", cursor: "pointer" }}
          alt=""
          onClick={() => history.push(`/admin/liveStreaming/${e?._id}`)}
        />
      );
    } else {
      return (
        <img
          className=" streams-image-two mt-2"
          src={e?.backgroundImage ? e?.backgroundImage : liveFive}
          style={{ height: "210px", cursor: "pointer" }}
          alt=""
          onClick={() => history.push(`/admin/liveStreaming/${e?._id}`)}
        />
      );
    }
  };
  const checkSliderImage = (e) => {
    const check = e?.backgroundImage?.includes("video");
    if (check) {
      return (
        <video
          controls
          autoplay
          muted
          className=" "
          src={e?.backgroundImage}
          style={{
            borderRadius: "40px",
            width: "500px",
            height: "300px",
            cursor: "pointer",
          }}
          alt=""
          onClick={() => history.push(`/admin/liveStreaming/${e?._id}`)}
        />
      );
    } else {
      return (
        <img
          className=""
          style={{
            borderRadius: "40px",
            width: "1000px",
            height: "250px",
            cursor: "pointer",
          }}
          src={e?.backgroundImage ? e?.backgroundImage : liveFive}
          alt=""
          onClick={() => history.push(`/admin/liveStreaming/${e?._id}`)}
        />
      );
    }
  };
  useEffect(() => {
    setStreamThumb(
      AllUser?.filter(
        (data) => data?.liveStreamStatus === "live" && data?._id !== userId.id
      )
    );
  }, [AllUser]);

  const makeLiveStream = () => {
    history.push(`/admin/liveStreaming/${userId?.id}`);
  };

  return (
    <div className="content">
      <LiveSection />

      <div className="main-live">
        {userData?.creator === true && userData?.eroxrFee === true && (
          <>
            <Row>
              <Col>
                <h1
                  className="text-white mt-3"
                  style={{ fontStyle: "Roboto", fontWeight: "600" }}
                >
                  Live Streaming
                </h1>
              </Col>
              <Col className="text-right mr-3">
                <Button
                  className="reset-button"
                  style={{ fontSize: "22px" }}
                  onClick={makeLiveStream}
                >
                  <BsCameraVideoFill
                    className="mr-lg-3"
                    style={{ fontSize: "25px" }}
                  />{" "}
                  Go live
                </Button>
              </Col>
            </Row>
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              slidesPerView={"3"}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
              }}
              pagination={{ el: ".swiper-pagination", clickable: true }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
                clickable: true,
              }}
              modules={[EffectCoverflow, Pagination, Navigation]}
              className="swiper_container"
            >
              {streamThumb ? (
                streamThumb?.map((data) =>
                  getSubscribedUser?.map(
                    (datas) =>
                      data?._id === datas?._id && (
                        <SwiperSlide
                          style={{
                            width: "40%",
                          }}
                        >
                          {checkSliderImage(data)}
                          {
                            // <div
                            //   style={{
                            //     display: "flex",
                            //     justifyContent: "end",
                            //     marginRight: "20px",
                            //   }}
                            //   className="ml-4"
                            // >
                            //   <AiFillHeart className="heart-live-pic" />
                            //   <FaShare className="share-live-pic" />
                            // </div>
                          }
                        </SwiperSlide>
                      )
                  )
                )
              ) : (
                <h3 className="text-center ml-lg-3 mt-5">No one streaming!</h3>
              )}

              <div className="slider-controler">
                <div
                  className="swiper-button-prev slider-arrow"
                  style={{ color: "white" }}
                >
                  <ion-icon name="arrow-back-outline"></ion-icon>
                </div>
                <div
                  className="swiper-button-next slider-arrow"
                  style={{ color: "white" }}
                >
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
              </div>
            </Swiper>

            <h3 className="text-white mt-2" style={{ fontStyle: "Roboto" }}>
              Streams You Might Be Like
            </h3>
            <Row>
              {streamThumb ? (
                streamThumb?.map((data) =>
                  getSubscribedUser?.map(
                    (datas) =>
                      data?._id === datas?._id && (
                        <Col xl={4}>
                          {checkBackgroundImage(data)}

                          <p alt="" className="stream-live ml-4 text-center">
                            Live
                          </p>
                        </Col>
                      )
                  )
                )
              ) : (
                <h3 className="text-center ml-lg-3 mt-5">No one streaming!</h3>
              )}
            </Row>
          </>
        )}
        <UserCheck userData={userData} />
      </div>
    </div>
  );
};

export default Live;
