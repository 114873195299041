import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import "./LiveSection.css";
import streamSix from "../../../assets/img/dummy.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import WidthCheck from "components/WidthCheck";

const LiveSection = () => {
  const [lives, setLives] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPortion, setIsOpenPortion] = useState(false);
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const getSubscribedUser = useSelector(
    (state) => state?.getAllAcceptedRequestReducer?.accpetedRequests
  );

  const getAllUser = useSelector((state) => state?.getAllUsers);
  const AllUser = getAllUser?.allUsers;
  useEffect(() => {
    setLives(
      AllUser?.filter(
        (data) => data?.liveStreamStatus === "live" && data?._id !== userId.id
      )
    );
  }, [AllUser]);

  const toggleOpenPortion = (val1, val2) => {
    setIsOpenPortion(val2);
    setIsOpen(val1);
  };

  return (
    <>
      <WidthCheck handleOpenValue={toggleOpenPortion} component="live"/>
      <div className={isOpen ? "open-chats" : "live-chats"}>
        <span className="ml-3" style={{ color: "white", fontSize: "30px" }}>
          <Link to="/admin/home" style={{ color: "white", fontSize: "50px" }}>
            <IoMdArrowRoundBack style={{ zIndex: "1100" }} />
          </Link>
        </span>

        <div className="live-main-chats">
          {lives ? (
            lives?.map((data) =>
              getSubscribedUser?.map(
                (datas) =>
                  data?._id === datas?._id && (
                    <>
                      <img
                        src={data?.profilePic ? data?.profilePic : streamSix}
                        alt="live"
                        style={{ width: "100px", height: "100px" }}
                        className="mt-5 stream-pics"
                      />{" "}
                      <br />
                    </>
                  )
              )
            )
          ) : (
            <h3 className="mt-5 text-center">No one streaming!</h3>
          )}
        </div>
      </div>
    </>
  );
};

export default LiveSection;
