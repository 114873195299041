import * as actionTypes from '../constants';
import axios from 'axios';
const url ="https://zany-lime-gharial-tam.cyclic.app/"
// const url = 'http://localhost:5000';


export const getPosts = (value) => async (dispatch) => {
    try {
        const  {data}  = await axios.post(`${url}/getPaginatedPost`,value);
        dispatch({ type: actionTypes.GET_POSTS_SUCCESS,payload:data.data });
        console.log(data.data,"=========>post data=======>")

    } catch (error) {
        dispatch({ type: actionTypes.GET_POSTS_FAIL, payload: error.response });
    }
};

export const getReduxPostsById = (state, userId) => {
//   const { users } = state;
//   return state?.posts?.find(post => post?.userId === userId);
  const filteredArray = state?.posts?.filter((item) => {
  // Replace with your condition
  return item?.userId === userId
});
return filteredArray
//    console.log( ,"=========>profile-user")
}
export const incrementCounter = (objectId, objectContainingCounterId,counterValue)  => async (dispatch)=> {
    try {
        console.log(objectId, objectContainingCounterId,counterValue,"in action========>")
        const data={
            objectId:objectId,
            objectContainingCounterId,
            counterValue

        }
         dispatch({ type: actionTypes.Poll_Counter_Increment, payload: data });
}
    
        // const { data } = await axios.get(`${url}/getAllPost`);;
        // dispatch({ type: actionTypes.GET_POSTS_SUCCESS, payload: data });
        // console.log(data,"=========>data-redux")


     catch (error) {
        // dispatch({ type: actionTypes.GET_POSTS_FAIL, payload: error.response });
    }
};
export const AddUserToPost = (postId, userId)  => async (dispatch)=> {
    try {
        console.log(postId, userId,"in action========>")
        const data={
            postId:postId,
            userId

        }
         dispatch({ type: actionTypes.ADD_USER_TO_POST, payload: data });
}
    
        // const { data } = await axios.get(`${url}/getAllPost`);;
        // dispatch({ type: actionTypes.GET_POSTS_SUCCESS, payload: data });
        // console.log(data,"=========>data-redux")


     catch (error) {
        // dispatch({ type: actionTypes.GET_POSTS_FAIL, payload: error.response });
    }
};

