import { toast } from "react-toastify";
import Swal from "sweetalert2";

export function showCallAlerts(name,message,text) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    await Swal.fire({
          title: `<p style="color:white;" font-size:15px">${name}" "${message}<p/>`,
          html: `<P style="color:white; font-size:10px">${text}</P>`,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Answer",
          cancelButtonText: "Reject",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn ml-2 btn-primary",
            cancelButton: "btn btn-danger",
          },
          timer: 10000,
          background: "#000000",
        }).then((result) => {
      resolve(result);
    });
  });
}


export const showSuccessAlert = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    theme: "dark",
  });
};
export const showErrorAlert = (msg) => {
  toast.error(msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,

          theme: "dark",
        });
};
export const showWarnAlert = (msg) => {
  toast.warn(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 4000,
    
      theme: 'dark',
     
    });
};

