import React, { useEffect, useState } from "react";
import { Button, Col, Label, Modal, Row } from "reactstrap";
import modalTwo from "./j38.png";
import modalThree from "./dummy.jpg";
import "./Modals.css";
import memberFour from "./j41.png";
import memberFive from "./j42.png";
import memberSix from "./j43.png";
import memberSeven from "./j44.png";
import memberTen from "./j52.png";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { sendRequest } from "Api/Api";
import { ToastContainer } from "react-toastify";
import { updatePost } from "Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { AddUserToPost } from "components/redux/actions/postActions";
import { AddUserToSubscription } from "components/redux/actions/requestActions";
import { showSuccessAlert } from "components/Alerts/Alerts";
import { showErrorAlert } from "components/Alerts/Alerts";
import { CARD_OPTIONS } from "components/DynamicFuntions";
import { ActionAnimation } from "components/LottieAnimation/LottieAnimations";

const LockModal = (props, val) => {
  const [showModal, setShowModal] = useState(false);
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const stripe = useStripe();
  const elements = useElements();
  const [animationCheck, setAnimationCheck] = useState(false);
  const [paymentCheck, setPaymentCheck] = useState("pending");
  const getUser = useSelector((state) => state?.getUserById);
  const userData = getUser?.userData;
  function toggleModal() {
    setShowModal(!showModal);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (props?.open === true) {
      toggleModal();
    }
  }, [props]);
  const handlePayment = async () => {
    setAnimationCheck(true);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (!error) {
        try {
          const { id } = paymentMethod;

          const values = {
            senderId: userId?.id,
            userData: userData,
            recieverId: props?.value?.userData?._id,
            payment: props?.value?.userData?.profilePrice,
            paymentId: id,
          };
          sendRequest(values).then((res) => {
            if (res.data.message === "payment Successful") {
              showSuccessAlert("payment Successfull");

              setAnimationCheck(false);
              toggleModal();

              // window.location.replace(window.location.href);
              dispatch(AddUserToSubscription(props?.value?.userData));
            } else {
              setAnimationCheck(false);
              showErrorAlert("Server Error");
            }
          });
        } catch (error) {
          setAnimationCheck(false);
          showErrorAlert("Server Error");
        }
      }
    } catch (ex) {
      setAnimationCheck(false);
      showErrorAlert("Server Error");
    }
  };
  const handlePaymentTwo = async () => {
    setAnimationCheck(true);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (!error) {
        try {
          const { id } = paymentMethod;

          const values = {
            postId: props?.value?._id,
            payerId: userId?.id,
            price: props?.value?.price,
            recieverId: props?.value?.userData?._id,
            paymentId: id,
          };

          await updatePost(values).then((res) => {
            if (res.data.message === "post updated") {
              showSuccessAlert("payment Successfull");
              dispatch(AddUserToPost(props?.value?._id, userId?.id));
              setAnimationCheck(false);
              toggleModal();
              // window.location.replace(window.location.href);
            } else {
              setAnimationCheck(false);
              setAnimationCheck(false);
              showErrorAlert("Server Error");
            }
          });
        } catch (error) {
          setAnimationCheck(false);
          showErrorAlert("Server Error");
        }
      }
    } catch (ex) {
      setAnimationCheck(false);
      showErrorAlert("Server Error");
    }
  };

  return (
    <div className="content">
      <div>
        <div
          style={{
            position: "absolute",
            top: "45%",
            left: "49%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={modalTwo}
            onClick={toggleModal}
            className="lock-img "
            alt=""
          />
        </div>

        <Modal
          isOpen={showModal}
          toggle={toggleModal}
          className="main-modal"
          style={{ maxWidth: "750px", borderRadius: "10px", marginTop: "50px" }}
        >
          <div className="modal-header"></div>

          <div className="modal-body home-modal">
            {paymentCheck === "pending" && (
              <>
                <h1 className="text-center mb-0">
                  <img
                    src={
                      props?.value?.userData?.profilePic
                        ? props?.value?.userData?.profilePic
                        : modalThree
                    }
                    style={{
                      width: "10%",
                      height: "10%",
                      borderRadius: "200px",
                    }}
                    alt=""
                  />
                </h1>
                <h4 className="text-center text-white mt-1 mb-0">
                  {props?.value?.userData?.firstName}{" "}
                  {props?.value?.userData?.lastName}
                </h4>
                <h6 className="text-center">Creator</h6>

                <Row className="mt-5">
                  <Col>
                    {props?.value?.userData?.profilePrice ? (
                      <>
                        <h5>
                          <span>
                            <i
                              className="fa fa-check mr-lg-2"
                              style={{ color: "green" }}
                              aria-hidden="true"
                            ></i>
                          </span>
                          Full access to user account
                        </h5>
                        <h5>
                          <span>
                            <i
                              className="fa fa-check mr-lg-2"
                              style={{ color: "green" }}
                              aria-hidden="true"
                            ></i>
                          </span>
                          Direct chat with user
                        </h5>
                        <h5>
                          <span>
                            <i
                              className="fa fa-check mr-lg-2"
                              style={{ color: "green" }}
                              aria-hidden="true"
                            ></i>
                          </span>
                          Cancel subscription at any time
                        </h5>
                        <h1 className="text-center">
                          <Button
                            className=" mt-2  modal-post-pay-button"
                            onClick={() => setPaymentCheck("subscription")}
                          >
                            Pay for Subsciption ${" "}
                            {props?.value?.userData?.profilePrice}
                          </Button>
                        </h1>
                      </>
                    ) : (
                      <h2 className="text-white text-center">
                        Sorry! No Profile Price.
                      </h2>
                    )}
                  </Col>
                  <Col className="">
                    <h5>
                      <span>
                        <i
                          className="fa fa-check mr-lg-2"
                          style={{ color: "green" }}
                          aria-hidden="true"
                        ></i>
                      </span>
                      View this user post
                    </h5>
                    <h5>
                      <span>
                        <i
                          className="fa fa-times mr-lg-2"
                          style={{ color: "red" }}
                          aria-hidden="true"
                        ></i>
                      </span>{" "}
                      No Direct chat with user
                    </h5>
                    <h5>
                      <span>
                        <i
                          className="fa fa-times mr-lg-2"
                          style={{ color: "red" }}
                          aria-hidden="true"
                        ></i>
                      </span>
                      No access to user account
                    </h5>
                    <h1 className="text-center">
                      <Button
                        className=" mt-2  modal-post-pay-button"
                        onClick={() => setPaymentCheck("post")}
                      >
                        Pay for this Post $ {props?.value?.price}
                      </Button>
                    </h1>
                  </Col>
                </Row>
              </>
            )}
            {paymentCheck === "subscription" && (
              <>
                <Row className="" style={{ marginTop: "-20px" }}>
                  <Col className="">
                    <h4 className="text-white" style={{ fontWeight: "600" }}>
                      Pay for profile
                    </h4>
                  </Col>
                  <Col className="text-right">
                    <h4 className="text-white" style={{ fontWeight: "600" }}>
                      $ {props?.value?.userData?.profilePrice}
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="">
                    <Row className="">
                      <Col>
                        <input
                          type="radio"
                          name="radio-group"
                          className="ml-3"
                          aria-label="Radio button"
                        />
                        <img
                          src={memberTen}
                          className="img-fluid ml-3"
                          alt="stripe"
                        />
                        <span
                          className="mt-2 ml-2"
                          style={{ color: "#8B8B8B" }}
                        >
                          Card
                        </span>
                      </Col>
                      <Col className="text-right">
                        <img
                          src={memberFour}
                          className="img-fluid ml-3 mt-1 "
                          alt="stripe"
                        />
                        <img
                          src={memberFive}
                          className="img-fluid ml-3 mt-1 "
                          alt="stripe"
                        />
                        <img
                          src={memberSix}
                          className="img-fluid ml-3 mt-1"
                          alt="stripe"
                        />
                        <img
                          src={memberSeven}
                          className="img-fluid ml-3 mt-1"
                          alt="stripe"
                        />
                      </Col>
                    </Row>
                    <Label
                      for="exampleEmail"
                      className="mt-4"
                      style={{
                        color: "white",
                        fontWeight: "700",
                        fontSize: "15px",
                      }}
                    >
                      Card number
                    </Label>
                    <CardElement options={CARD_OPTIONS} className="mt-2" />

                    {animationCheck ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {ActionAnimation()}
                      </div>
                    ) : (
                      <>
                        <h1 className="text-center mt-4 mb-0">
                          <Button
                            className=" pay-submit-btn"
                            onClick={handlePayment}
                          >
                            Submit
                          </Button>
                        </h1>
                        <h1 className="text-center mb-0">
                          <Button
                            type="button"
                            className="post-cancel-btn"
                            onClick={toggleModal}
                          >
                            Cancel
                          </Button>
                        </h1>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
            {paymentCheck === "post" && (
              <>
                <Row className="" style={{ marginTop: "-20px" }}>
                  <Col className="">
                    <h4 className="text-white" style={{ fontWeight: "600" }}>
                      Pay for post
                    </h4>
                  </Col>
                  <Col className="text-right">
                    <h4 className="text-white" style={{ fontWeight: "600" }}>
                      $ {props?.value?.price}
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="">
                    <Row className="">
                      <Col>
                        <input
                          type="radio"
                          name="radio-group"
                          className="ml-3"
                          aria-label="Radio button"
                        />
                        <img
                          src={memberTen}
                          className="img-fluid ml-3"
                          alt="stripe"
                        />
                        <span
                          className="mt-2 ml-2"
                          style={{ color: "#8B8B8B" }}
                        >
                          Card
                        </span>
                      </Col>
                      <Col className="text-right">
                        <img
                          src={memberFour}
                          className="img-fluid ml-3 mt-1 "
                          alt="stripe"
                        />
                        <img
                          src={memberFive}
                          className="img-fluid ml-3 mt-1 "
                          alt="stripe"
                        />
                        <img
                          src={memberSix}
                          className="img-fluid ml-3 mt-1"
                          alt="stripe"
                        />
                        <img
                          src={memberSeven}
                          className="img-fluid ml-3 mt-1"
                          alt="stripe"
                        />
                      </Col>
                    </Row>
                    <Label
                      for="exampleEmail"
                      className="mt-4"
                      style={{
                        color: "white",
                        fontWeight: "700",
                        fontSize: "15px",
                      }}
                    >
                      Card number
                    </Label>
                    <CardElement options={CARD_OPTIONS} className="mt-2" />

                    {animationCheck ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {ActionAnimation()}
                      </div>
                    ) : (
                      <>
                        <h1 className="text-center mt-4 mb-0">
                          <Button
                            className=" pay-submit-btn"
                            onClick={handlePaymentTwo}
                          >
                            Submit
                          </Button>
                        </h1>
                        <h1 className="text-center mb-0">
                          <Button
                            type="button"
                            className="post-cancel-btn"
                            onClick={toggleModal}
                          >
                            Cancel
                          </Button>
                        </h1>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LockModal;
