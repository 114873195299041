import React, { useEffect, useState } from "react";
import profilFive from "../../assets/img/j15.png";
import profilSix from "../../assets/img/j33.png";
import profilSeven from "../../assets/img/dummy.jpg";
import profileEight from "../../assets/img/j35.png";
import "./Profile.css";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiOutlineEye,
  AiOutlineLineChart,
} from "react-icons/ai";
import FileBase64 from "react-file-base64";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Row, Col } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { getReduxUserById } from "components/redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { getReduxPostsById } from "components/redux/actions/postActions";
import { changeOnlineStatus } from "Api/Api";
import Poll from "components/Poll/Poll";
import { getUserById } from "components/redux/actions/userActions";
import { getAllUsers } from "components/redux/actions/userActions";
import { updateUserCover } from "Api/Api";
import SubscriptionModal from "components/Modals/SubscriptionModal";
import { Elements } from "@stripe/react-stripe-js";
import LockModal from "components/Modals/LockModal";
import { getPosts } from "components/redux/actions/postActions";
import { Link } from "react-router-dom";
import { checkPost } from "components/DynamicFuntions";
import { uploadImageToFirebase } from "components/DynamicFuntions";
import { showSuccessAlert } from "components/Alerts/Alerts";
import { showErrorAlert } from "components/Alerts/Alerts";
import { uploadVideoToFirebase } from "components/DynamicFuntions";
import stripePromise from "Api/Stripe";

const Profile = () => {
  const history = useHistory();
  const { id } = useParams();
  const [isChecked, setIsChecked] = useState(false);
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [isHovered, setIsHovered] = useState(false);
  const [privateCheck, setPrivateCheck] = useState("posts");
  const [requestCheck, setRequestCheck] = useState(false);
  const [backgroundVideoCheck, setBackgroundVideoCheck] = useState(false);
  const [lockModal, setLockModal] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState();
  let sendingId = "";
  if (id === ":id") {
    sendingId = userId.id;
  } else {
    sendingId = id;
  }
  const user = useSelector((state) =>
    getReduxUserById(state?.getAllUsers?.allUsers, sendingId)
  );
  const AllUserPosts = useSelector((state) =>
    getReduxPostsById(state?.getPosts, sendingId)
  );
  const getSubscribedUser = useSelector(
    (state) => state?.getAllAcceptedRequestReducer?.accpetedRequests
  );

  const userData = user;
  useEffect(() => {
    const check = userData?.backgroundImage?.includes("video");
    if (check) {
      setBackgroundVideoCheck(true);
      setBackgroundImage(userData?.backgroundImage);
      setIsChecked(userData?.onlineStatus);
    } else {
      setBackgroundVideoCheck(false);
      setBackgroundImage(userData?.backgroundImage);
      setIsChecked(userData?.onlineStatus);
    }
  }, [userData]);

  const dispatch = useDispatch();
  const values = {
    userId: userId.id,
  };

  let posts = [];
  let privat = [];
  let poll = [];

  AllUserPosts?.map((data) => {
    if (data?.postCheck === true) {
      privat.push(data);
    }
    if (data?.key === "post") {
      posts.push(data);
    }
    if (data?.key === "poll") {
      poll.push(data);
    }
  });

  useEffect(() => {
    getSubscribedUser?.map((data) => {
      if (data?._id === sendingId) {
        setRequestCheck(true);
      }
    });
  }, [requestCheck]);
  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getUserById(values));
    dispatch(getPosts());
  }, [dispatch]);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
    const values = {
      onlineStatus: isChecked,
      userId: userId.id,
    };

    changeOnlineStatus(values);
  };
  const handleBackgroundPic = async (e) => {
    console.log(e, "pic");
    setBackgroundImage(e.selectedFile.base64);

    const type = e.selectedFile.base64.substring(5, 10);
    if (type === "image") {
      setBackgroundVideoCheck(false);
      const result = await uploadImageToFirebase(e.selectedFile.base64);

      if (result) {
        const values = {
          backgroundImage: result,
          userId: userId.id,
        };
        updateUserCover(values).then((res) => {
          if (res.data.message === "user updated") {
            showSuccessAlert("Cover photo updated");
          } else {
            showErrorAlert("Server error");
          }
        });
      }
    } else if (type === "video") {
      setBackgroundVideoCheck(true);
      const result = await uploadVideoToFirebase(e.selectedFile.base64);
      if (result) {
        const values = {
          backgroundImage: result,
          userId: userId.id,
        };
        updateUserCover(values).then((res) => {
          if (res.data.message === "user updated") {
            showSuccessAlert("Cover photo updated");
          } else {
            showErrorAlert("Server error");
          }
        });
      }
    } else {
      showErrorAlert("Please select valid image!");
    }
  };

  const checkPostTwo = (post) => {
    const check = post?.includes("video");
    if (check) {
      return (
        <video
          loop
          muted
          controls
          autoPlay
          src={post}
          className="profile-posts"
        />
      );
    } else {
      return <img src={post} alt="" className="mt-2  ml-lg-4 profile-posts" />;
    }
  };

  return (
    <div className="content profile-div ">
      {backgroundVideoCheck === false && (
        <Row className="mr-0">
          <Col xl={12} className="mr-0">
            <div
              style={{
                backgroundImage: `url(${
                  backgroundImage ? backgroundImage : profilSix
                })`,

                backgroundSize: "cover",
                height: "350px",
                position: "relative",
              }}
              className="profileBackground"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              <br />
              {id === ":id" && (
                <h2 className="text-right mr-5 mt-4">
                  <i
                    className="background-upload fa fa-cog mr-5 "
                    style={{ opacity: isHovered ? "1" : "0" }}
                  ></i>
                </h2>
              )}

              <div
                style={{
                  opacity: "0",
                  position: "absolute",
                  zIndex: "10",
                  align: "center",
                  marginLeft: "85%",
                  marginTop: "-7%",
                  fontSize: "30px",
                  paddingTop: "15px",
                }}
              >
                <FileBase64
                  accept=".jpg, .jpeg, .png, .mp4"
                  className="text-center"
                  onDone={(base64) =>
                    handleBackgroundPic({ selectedFile: base64 })
                  }
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  transform: "translate(50%, 50%)",
                }}
              >
                <img
                  src={
                    userData?.profilePic ? userData?.profilePic : profilSeven
                  }
                  alt="Profile"
                  className="rounded-circle"
                  style={{
                    border: "3px solid white",
                    height: "100px",
                    width: "100px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      )}
      {backgroundVideoCheck === true && (
        <div
          style={{
            backgroundSize: "cover",
            height: "350px",
            position: "relative",
          }}
          className="profileBackground"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <video
            src={backgroundImage}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              // objectFit: 'cover',
            }}
            loop
            muted
            controls
            autoPlay
          />
          <br />
          {id === ":id" && (
            <h2 className="text-right mr-5 mt-4">
              <i
                className="background-upload fa fa-cog mr-5"
                style={{ opacity: isHovered ? "1" : "0" }}
                onMouseOver={handleMouseOver}
              ></i>
            </h2>
          )}
          <div
            style={{
              opacity: "0",
              position: "absolute",
              zIndex: "10",
              align: "center",
              marginLeft: "85%",
              marginTop: "-8%",
              fontSize: "30px",
              paddingTop: "15px",
            }}
          >
            <FileBase64
              accept=".jpg, .jpeg, .png, .mp4"
              className="text-center"
              onDone={(base64) => handleBackgroundPic({ selectedFile: base64 })}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "0",
              left: "0",
              transform: "translate(50%, 50%)",
            }}
          >
            <img
              src={userData?.profilePic ? userData?.profilePic : profilSeven}
              alt="Profile"
              className="rounded-circle"
              style={{
                border: "3px solid white",
                height: "100px",
                width: "100px",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      )}
      <Row className="profile-user-row">
        <Col>
          <Row className="mb-0">
            <h3 className="mt-3 user-name mb-0 ml-lg-3 ">
              {userData?.username ? userData?.username : userData?.firstName}
            </h3>
            {id === userId?.id && (
              <>
                {
                  // <CustomInput
                  //   type="switch"
                  //   id="exampleCustomSwitch"
                  //   name="customSwitch"
                  //   checked={isChecked}
                  //   onClick={toggleSwitch}
                  //   className="mt-4 ml-2"
                  // />
                }
                <label class="switch mt-3 ml-2">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onClick={toggleSwitch}
                  />
                  <span class="slider round"></span>
                </label>
                <Button
                  className="btn-sm mt-3 ml-lg-3  profile-button"
                  onClick={() => history.push("/admin/editProfile")}
                >
                  Edit Profile
                </Button>
              </>
            )}
            {id === ":id" && (
              <>
                {
                  // <CustomInput
                  //   type="switch"
                  //   id="exampleCustomSwitch"
                  //   name="customSwitch"
                  //   checked={isChecked}
                  //   onClick={toggleSwitch}
                  //   className="mt-4 ml-2"
                  // />
                }
                <label class="switch mt-3 ml-2">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onClick={toggleSwitch}
                  />
                  <span class="slider round"></span>
                </label>
                <Button
                  className="btn-sm mt-3 ml-lg-3  profile-button"
                  onClick={() => history.push("/admin/editProfile")}
                >
                  Edit Profile
                </Button>
              </>
            )}
          </Row>
          {userData?.creator && (
            <p className=" profile-designation mt-0 ">G.O.A.T Creator</p>
          )}
          {sendingId !== userId?.id && requestCheck ? (
            <Row>
              <Button className="btn-sm mt-3 ml-2 profile-button">
                <img
                  alt=""
                  className="mr-2"
                  src={profilFive}
                  style={{ color: "white" }}
                />
                subscribed
              </Button>
              {
                // <Button
                //   className="add-button btn-sm  mt-3 p-1"
                //   style={{ border: "2px solid white" }}
                // >
                //   1K
                // </Button>
              }
            </Row>
          ) : (
            sendingId !== userId?.id && (
              <Row>
                <Elements stripe={stripePromise}>
                  <SubscriptionModal value={userData} />
                </Elements>
                {
                  // <Button
                  //   className="add-button btn-sm  mt-3 p-1"
                  //   style={{ border: "2px solid white" }}
                  // >
                  //   1K
                  // </Button>
                }
              </Row>
            )
          )}
          <Row>
            <Col xl={2}>
              <h4
                className="text-white mt-1 mb-0"
                style={{ fontWeight: "600" }}
              >
                About
              </h4>
              <p onClick={() => history.push("/admin/editProfile")}>
                {userData?.about ? userData?.about : "No Bio!"}
              </p>
            </Col>
          </Row>
          <a href={userData?.website} style={{ color: "blue" }}>
            {userData?.website}
          </a>

          <h4
            className="text-white mt-1 mb-0 mr-5"
            style={{ fontWeight: "700" }}
          >
            Wishlist
          </h4>
          <Link to={`/admin/wishlist/${sendingId}`}>
            <a className="option-poll">view wishlist</a>
          </Link>

          <Row>
            <AiFillInstagram
              className="ml-3"
              style={{ color: "white", fontSize: "20px" }}
            />
            <AiFillFacebook
              className="ml-2"
              style={{ color: "white", fontSize: "20px" }}
            />
          </Row>
        </Col>
      </Row>
      <hr
        style={{ backgroundColor: "#555555", height: "2px" }}
        className="mr-3 ml-5"
      />

      <div
        className=""
        style={{ marginTop: "-10px", marginLeft: "10%", display: "flex" }}
      >
        <img
          src={profileEight}
          alt=""
          style={{ color: "white", width: "18px", height: "18px" }}
          className="ml-5"
        />
        <span
          className="ml-2"
          onClick={() => setPrivateCheck("posts")}
          style={{
            color: privateCheck === "posts" && "white",
            fontWeight: "600",
            cursor: "pointer",
          }}
        >
          Posts
        </span>

        {id === ":id" && (
          <>
            <AiOutlineEye
              style={{ color: "grey", fontSize: "20px", cursor: "pointer" }}
              className="ml-lg-5"
            />

            <span
              className="ml-2"
              onClick={() => setPrivateCheck("private")}
              style={{
                fontWeight: "600",
                cursor: "pointer",
                color: privateCheck === "private" && "white",
              }}
            >
              Private
            </span>
          </>
        )}
        <AiOutlineLineChart
          style={{ color: "grey", fontSize: "20px", cursor: "pointer" }}
          className="ml-lg-5"
        />

        <span
          className="ml-2"
          onClick={() => setPrivateCheck("poll")}
          style={{
            fontWeight: "600",
            cursor: "pointer",
            color: privateCheck === "poll" && "white",
          }}
        >
          Poll
        </span>
      </div>

      <Row className=" ml-lg-4">
        {privateCheck === "posts" &&
          posts?.map((data) => (
            <Col xl={4} className="mt-5">
              {
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    borderRadius: "40px",
                    overflow: "hidden",
                  }}
                >
                  {checkPost(data, getSubscribedUser, userId)}
                  {userId?.id !== data?.userId && (
                    <>
                      {getSubscribedUser?.length &&
                      getSubscribedUser.some(
                        (datas) => datas?._id === data?.userId
                      ) ? (
                        <></>
                      ) : (
                        <>
                          {data?.payerId.length &&
                          data?.payerId.includes(userId?.id) ? (
                            <></>
                          ) : (
                            <>
                              {data?.postCheck === true && (
                                <Elements stripe={stripePromise}>
                                  <LockModal open={lockModal} value={data} />
                                </Elements>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              }
            </Col>
          ))}
        {privateCheck === "private" &&
          privat?.map((data) => (
            <Col xl={4} className=" mt-5">
              {checkPostTwo(data?.postPic)}
            </Col>
          ))}
        {privateCheck === "poll" &&
          poll?.map((data) => (
            <Col xl={6} className=" mt-5">
              <Poll data={data} />
            </Col>
          ))}
      </Row>

      <ToastContainer />
    </div>
  );
};

export default Profile;
