import React, { useEffect, useState } from "react";
import { AiFillDollarCircle } from "react-icons/ai";
import "./DashboardSection.css";
import dashboardProfile from "../../../assets/img/dummy.jpg";
import { Card, Row, Col } from "reactstrap";
import { FaMoneyBillWaveAlt, FaUserShield } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getReduxSubscribedUser } from "components/redux/actions/paymentAction";
import { getUserById } from "components/redux/actions/userActions";
import { getAllTip } from "components/redux/actions/paymentAction";
import { getAllCreatorRequest } from "components/redux/actions/creatorActions";
import WidthCheck from "components/WidthCheck";
import { getRequestBySenderId } from "components/redux/actions/requestActions";

const DashboardSection = () => {
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPortion, setIsOpenPortion] = useState(false);
  const Values = {
    userId: userId.id,
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserById(Values));
    dispatch(getAllCreatorRequest());
    dispatch(getAllTip());
    dispatch(getRequestBySenderId(Values));
  }, [dispatch]);

  const toggleOpenPortion = (val1, val2) => {
    setIsOpenPortion(val2);
    setIsOpen(val1);
  };
  const tipUsers = useSelector((state) =>
    getReduxSubscribedUser(state?.getAllTip, userId?.id)
  );
  const subscribedUsers = useSelector(
    (state) => state?.getAllSenderRequestReducer
  );
  const subscribedData = subscribedUsers?.senderAllRequests;
  const getUser = useSelector((state) => state?.getUserById);
  const userData = getUser?.userData;
  let total = 0;

  subscribedData?.map((data) => {
    total += data?.paymentData?.payment || 0;
  });

  tipUsers?.map((data) => {
    total += data?.paymentData?.tip || 0;
  });

  return (
    <>
      <WidthCheck handleOpenValue={toggleOpenPortion} component="dashboard"/>
      <div className={isOpen ? "open d-none" : "dashboard"}>
        <div style={{ display: "flex", marginTop: "20%", marginLeft: "10%" }}>
          <div className="dash-top-icons" style={{ marginLeft: "10%" }}>
            <div>
              <img
                style={{
                  marginLeft: "10%",
                  marginTop: "-2%",
                  width: "70px",
                  height: "50px",
                  borderRadius: "60px 60px 60px 60px",
                }}
                src={
                  userData?.profilePic ? userData?.profilePic : dashboardProfile
                }
                alt="profile"
              />
            </div>
          </div>
          <h2 className="ml-lg-3 mt-2">
            {userData?.username ? userData?.username : userData?.firstName}{" "}
            {userData?.username ? userData?.username : userData?.lastName}
          </h2>
        </div>

        <div className="dashboard-cards">
          <Card
            className="ml-lg-3 dash-cards"
            style={{ borderRadius: "20px", height: "250px", width: "350px" }}
          >
            <h2 className="text-white mt-3 ml-lg-4 mb-1">
              Total Subscriptions
            </h2>
            <hr className="ml-2 mr-2" style={{ backgroundColor: "white" }} />
            <Row className="ml-lg-4 mt-4">
              <Col>
                <h3 className="dash-icons">{subscribedData?.length}</h3>
              </Col>
              <Col>
                <FaUserShield className="dash-icons mt-1" />
              </Col>
            </Row>
          </Card>
          <Card
            className="ml-lg-3 dash-cards"
            style={{ borderRadius: "20px", height: "250px", width: "350px" }}
          >
            <h2 className="text-white mt-3 ml-lg-4 mb-1">Total Tips</h2>
            <hr className="ml-2 mr-2 " style={{ backgroundColor: "white" }} />
            <Row className="ml-lg-4 mt-4">
              <Col>
                <h3 className="dash-icons">{tipUsers?.length}</h3>
              </Col>
              <Col>
                <AiFillDollarCircle className="dash-icons mt-1" />
              </Col>
            </Row>
          </Card>
          <Card
            className="ml-lg-3 dash-cards"
            style={{ borderRadius: "20px", height: "250px", width: "350px" }}
          >
            <h2 className="text-white mt-3 mb-1 ml-lg-4">Total Revenue</h2>
            <hr className="ml-2 mr-2 " style={{ backgroundColor: "white" }} />
            <Row className="ml-lg-4 mt-4">
              <Col>
                <h3 className="dash-icons">{total}</h3>
              </Col>
              <Col>
                <FaMoneyBillWaveAlt className="dash-icons mt-1" />
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </>
  );
};

export default DashboardSection;
