import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import "./Modals.css";
import { updatePicStatus } from "Api/Api";

const ChatImageModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [timer, setTimer] = useState(15);
  function toggleModal() {
    setShowModal(!showModal);
  }
  let intervalId = null;
  useEffect(() => {
    if (showModal) {
      startTimer();
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [showModal]);

  const settingInterval = () => {
    const values = {
      id: props?.image?.data?._id,
    };
    updatePicStatus(values);
    toggleModal();

    props?.image?.settingInterval(props?.image?.imageString);
  };
  const startTimer = () => {
    setTimer(15);
    intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
  };
  console.log(props?.image?.data);

  return (
    <div className="content">
      <div>
        <div className="button-container chat-second-img">
          <button className="tap-button tap-img-btn" onClick={settingInterval}>
            <span className="tap-text">Tap to Open</span>
          </button>
        </div>
        <Modal
          isOpen={showModal}
          toggle={toggleModal}
          style={{
            maxWidth: "500px",
            borderRadius: "10px",
            marginTop: "100px",
          }}
        >
          <div className="modal-header"></div>
          <div className="modal-body ">
            <h5 className="text-center text-white">
              This image is visible for only {timer} seconds
            </h5>
            <h1 className="text-center">
              <img
                className=""
                src={props?.image?.imageString}
                style={{ width: "500px", height: "300px" }}
                alt=""
              />
            </h1>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ChatImageModal;
